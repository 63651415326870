import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  TextAlign,
  ThemeColor,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  IImageConfig,
  ImageConfig,
} from '@ra-web-tech-ui-toolkit/common-utils/image';
import {
  IStaticTextConfig,
  StaticTextConfig,
  TextAlignVertically,
} from '@ra-web-tech-ui-toolkit/common-utils/static-text';

import { errorPagesText, errorPagesTitle } from 'src/app/constants'

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  title : string = errorPagesTitle.notAuthorized
  errorText : string = errorPagesText.unauthorizedErrorText
  themeColor = ThemeColor;
  config: IStaticTextConfig = new StaticTextConfig({
    allowMultiline: true,
    textAlign: TextAlign.Center,
    textAlignVertically: TextAlignVertically.Center,
  });
  configImg: IImageConfig = new ImageConfig({
    disablePadding: true,
  });

  constructor(private router: Router) {}

  backHome() {
    this.router.navigate(['/dashboard']);
  }
}
