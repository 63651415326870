import { Component } from '@angular/core';
import {
  CursorStyle,
  NotificationType,
  ThemeColor,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import { INodeListApiObject } from '../models/devicesList.model';
import { CommunicationService } from '../service/communication.service';
import { SearchUtils } from '../utils/search.utils';
import { IStaticTextConfig, StaticTextConfig } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { cardDetailsText } from 'src/app/constants';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
  providers: [SearchUtils],
})
export class CardDetailsComponent {
  activeStatusIcon = NotificationType.Success;
  NodeButtonColor = ThemeColor.Primary;
  buttonColor = ThemeColor.Primary;
  emptyImage: boolean = true;
  nodeCount: number = 0;
  nodeOnlinePercentage : number = 0;
  nodeDetails: any;
  instanceCount: number = 0;
  appCount: number = 0;
  instanceUpdateCount: number = 0;
  edgeAppUpdateCount: number = 0;
  isDataLoading: boolean = true;
  onlineText : string = cardDetailsText.staticTextForOnline
  addEdgeNodeText : string = cardDetailsText.addEdgeNodeValueText
  vulnerabilitiesText : string = cardDetailsText.vulnerabilitiesValueText
  updateText : string = cardDetailsText.updateValueText
  addAppInstanceText : string = cardDetailsText.addAppInstancesText
  textConfig: IStaticTextConfig = new StaticTextConfig({
    cursorStyle:CursorStyle.Pointer
  });

  constructor(
    private onChangeService: CommunicationService,
    private utils: SearchUtils
  ) {
    this.nodeCount = 0;
    this.getNodeDetailsChanges();
    this.getInstanceDetailsChanges();
    this.getEdgeAppDetailsChanges();
    this.checkLoading();
  }

  getNodeDetailsChanges() {
    this.onChangeService.getNodeChanges$.subscribe(
      (response: INodeListApiObject) => {
        this.nodeDetails = response.summaryByState;
        this.nodeCount = response.summaryByState.total;
        this.nodeOnlinePercentage = this.utils.calculatePercentage(
          this.nodeCount,
          response.summaryByState.values.Online
        );
      }
    );
  }

  getInstanceDetailsChanges() {
    this.onChangeService.getInstanceChanges$.subscribe((response: any) => {
      this.instanceCount = response.summaryByState.total;
      this.instanceUpdateCount = this.utils.calculateInstanceUpdates(
        response.list
      );
    });
  }

  getEdgeAppDetailsChanges() {
    this.onChangeService.getEdgeAppChanges$.subscribe((response: any) => {
      this.appCount = response.summaryByOrigin.total;
      this.edgeAppUpdateCount = this.utils.calculateEdgeAppUpdates(
        response.list
      );
    });
  }

  sendUrl(value: string) {
    if(value == "edge-nodes/add"){
      this.onChangeService.checkNodeLimit()
    }else{
      this.onChangeService.redirectTo(value);
    }
  }

  checkLoading() {
    this.onChangeService.getLoaderChanges$.subscribe((isLoading: boolean) => {
      this.isDataLoading = isLoading;
    });
  }
}
