import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUserDetails } from 'src/app/auth/model/userDetails.model';
import { RedirectService } from 'src/app/auth/service/redirect.service';
import { AuthorizationService } from 'src/app/shared/utils/services/authorization.service';
import { environment } from 'src/environments/environment';
import { IEdgeAppAPIObj } from '../models/appList.model';
import { INodeListApiObject } from '../models/devicesList.model';
import { IInstanceAPIObj } from '../models/instances.model';
import { IProjectSelected } from '../models/project.model';
import { CommunicationService } from '../service/communication.service';
import { DashboardService } from '../service/dashboard.service';
import { PollingService } from '../service/polling.service';

interface IMainDetailContentExampleConfiguration {
  title: string;
  subtitle: string;
  disableSidePaddings: boolean;
  disableTopDivider: boolean;
  disableBottomDivider: boolean;
}

@Component({
  selector: 'app-main-detail',
  templateUrl: './main-detail.component.html',
  styleUrls: ['./main-detail.component.scss'],
})
export class MainDetailComponent implements OnInit, OnDestroy {
  userDetails: IUserDetails | undefined;
  nodeSummary: any;
  triggerApiFunctionSubscription$!: Subscription;

  public mainDetailContent: IMainDetailContentExampleConfiguration = {
    title: '',
    subtitle: '',
    disableSidePaddings: false,
    disableTopDivider: true,
    disableBottomDivider: true
  };

  constructor(
    private redirectService: RedirectService,
    private dashboardService: DashboardService,
    private onChangeService: CommunicationService,
    private pollingService: PollingService,
    private sharedService: AuthorizationService,
    public window: Window
  ) {}

  ngOnInit(): void {
    this.getOrgAndEmail();
    this.onChangeService.getProjectChanges$.subscribe(
      (project: IProjectSelected) => {
        this.onChangeService.loaderChanged(true);
        if (this.triggerApiFunctionSubscription$) {
          this.triggerApiFunctionSubscription$.unsubscribe();
        }
        this.pollingService.stopPolling();
        this.triggerApiFunctionSubscription$ = this.pollingService
          .getTriggerObservable()
          .subscribe(async () => {
            Promise.all([
              await this.getInstanceList(project.name),
              await this.getDevicesList(project.name),
              await this.getEdgeAppApiCall(),
            ]).finally(() => {
              this.onChangeService.loaderChanged(false);
            });
          });
        this.pollingService.startPooling();
      }
    );
    this.onChangeService.redirectionRequests$.subscribe((newUrl: string) => {
      this.urlZededaRedirect(newUrl, this.userDetails);
    });
  }

  ngOnDestroy() {
    this.pollingService.stopPolling();
    if (this.triggerApiFunctionSubscription$) {
      this.triggerApiFunctionSubscription$.unsubscribe();
    }
  }

  getOrgAndEmail() {
    this.redirectService.getOrgNameEmail().subscribe({
      next: (result: IUserDetails) => {
        this.userDetails = result;
      },
      error: () => {},
    });
  }

  getDevicesList(projectName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dashboardService.getDevicesList(projectName).subscribe(
        (result: INodeListApiObject) => {
          this.onChangeService.nodeChangedByProject(result);
          resolve();
        },
        (error) => this.pollingService.stopPolling()
      );
    });
  }

  getInstanceList(projectName: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dashboardService.getInstanceByProjectName(projectName).subscribe(
        (result: IInstanceAPIObj) => {
          this.onChangeService.instanceChangedByProject(result);
          resolve();
        },
        (error) => this.pollingService.stopPolling()
      );
    });
  }

  getEdgeAppApiCall(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dashboardService
        .getEdgeAppList()

        .subscribe({
          next: (result: IEdgeAppAPIObj) => {
            this.onChangeService.edgeAppFetch(result);
            resolve();
          },
          error: () => this.pollingService.stopPolling(),
        });
    });
  }

  urlZededaRedirect(url: string, UserDetails?: IUserDetails): void {
    if (UserDetails) {
      this.window.open(
        environment.appConfiguration.redirectUrl +
          url +
          `?user=${
            UserDetails.userEmail
          }&enterprise=${this.sharedService.readCurrentOrgName()}`,
        '_blank'
      );
    } else {
      this.window.open(
        environment.appConfiguration.redirectUrl + url || '',
        '_blank'
      );
    }
  }
}
