import { Component, OnInit } from '@angular/core';
import { IToggleIconButton } from '@ra-web-tech-ui-toolkit/buttons/button-toggle';
import {
  IconSize,
  IInnerItem,
  NotificationType,
  TextAlign,
  ThemeColor,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  IStaticTextConfig,
  StaticTextConfig,
  TextAlignVertically,
} from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import {
  INodeSearchList,
} from '../models/devicesList.model';
import { CommunicationService } from '../service/communication.service';
import { AppUtils } from '../utils/app-utils';
import {
  IImageConfig,
  ImageConfig,
} from '@ra-web-tech-ui-toolkit/common-utils/image';
import { distinctUntilChanged } from 'rxjs';
import { edgeNodeLabels } from 'src/app/constants';

@Component({
  selector: 'app-edge-nodes',
  templateUrl: './edge-nodes.component.html',
  styleUrls: ['./edge-nodes.component.scss'],
})
export class EdgeNodesComponent implements OnInit {
  themeColor = ThemeColor;
  step = 0;
  isDataLoading: boolean = true;
  buttonShowLessText : string = edgeNodeLabels.buttonTextLabelShowLess
  buttonShowAllText : string = edgeNodeLabels.buttonTextLabelShowAll
  contentText : string = edgeNodeLabels.contentTextValue
  outlinedButtonText : string = edgeNodeLabels.oulinedButtonTextLabel
  config: IStaticTextConfig = new StaticTextConfig({
    allowMultiline: true,
    textAlign: TextAlign.Center,
    textAlignVertically: TextAlignVertically.Center,
  });
  configImg: IImageConfig = new ImageConfig({
    disablePadding: true,
  });
  items: IToggleIconButton[] = [
    {
      icon: 'ra-icon-ide-sm-caret-down',
      id: 'dropDownSmall',
      usePaneIndicator: false,
    },
  ];
  statusIcons: IInnerItem[] = [
    { value: NotificationType.Info },
    { value: NotificationType.Success },
    { value: NotificationType.Error },
    { value: NotificationType.Warning },
  ];
  activeStatusIcon = <NotificationType>this.statusIcons[1].value;
  toggleConfig = {
    iconSize: IconSize.S,
    useInvertedMode: false,
    useAltDisabledColor: false,
  };
  displayedColumns: string[] = ['status', 'appInstanceName', 'nodeName'];
  edgeNodes: Array<INodeSearchList> = [];

  constructor(public onChangeService: CommunicationService) {
    this.onChangeService.getNodeSearchChanges$.pipe(
      distinctUntilChanged((previous: any, current: any) => {
        return JSON.stringify(previous) === JSON.stringify(current);
      })
    ).subscribe({
      next: (response: Array<INodeSearchList>) => {
        this.edgeNodes = response;
      },
    });
  }

  ngOnInit(): void {
    this.checkLoading();
  }

  getStatus(runState: string) {
    return AppUtils.getStatusString(runState);
  }

  urlZededaRedirect(appInstanceId: string) {
    this.onChangeService.redirectTo(
      'edge-app-instances/' + appInstanceId + '/details/status'
    );
  }

  redirectUrl(url:string){
    this.onChangeService.redirectTo(url);
  }

  checkLoading() {
    this.onChangeService.getLoaderChanges$.subscribe((isLoading: boolean) => {
      this.isDataLoading = isLoading;
    });
  }
}
