<div class="about-dialog" fxLayout="column">
  <div class="about-header">
      <div class="about-logo" [style.background-image]="'url(/assets/images/AboutLogo.svg)'">
      </div>
      <div class="item-exit">
        <ra-ui-icon-button
        (onClick)="closeButtonClicked()"
        [config]="buttonConfig"
        icon="ra-icon-ide-md-cross"></ra-ui-icon-button>
      </div>
  </div>
  <div class="about-body">
    <div class="about-title">
      <ra-ui-static-text [value]="aboutDialogTitleText" [config]="titleTextConfig"></ra-ui-static-text>
    </div>
    <div class="about-version medium-font">
      <ra-ui-static-text [value]="'Version ' + serverVersion + UiVersion" [config]="textConfig"></ra-ui-static-text>
      <ra-ui-static-text #linkText
      id="linkModeStaticText"
      [value]="linkModeStaticText"
      [href]="links.releaseNotes"
      [config]="textLinkConfig"
      ></ra-ui-static-text>&nbsp;
    </div>
    <div class="about-details">
      <ra-ui-static-text [value]="aboutContent" [config]="{allowMultiline:true}" ></ra-ui-static-text>&nbsp;
      <div class="agreement-text">
        <ra-ui-static-text [value]="agreementText"></ra-ui-static-text>
        <ra-ui-static-text [href]="links.licenseAgreement" [config]="textLinkConfig" [value]="licenseAgreementText"></ra-ui-static-text>
        <ra-ui-static-text [value]="additionalText"></ra-ui-static-text>
      </div>
      <div class="agreement-text">
        <ra-ui-static-text [value]="openSourceInfoText"></ra-ui-static-text>
        <ra-ui-static-text [href]="'/3rdpartylicenses.txt'" [config]="textLinkConfig" [value]="thirdPartyAttributionText"></ra-ui-static-text>
      </div>
      <div class="agreement-text">
        <ra-ui-static-text [value]="coveredPropertiesText"></ra-ui-static-text>
        <ra-ui-static-text [href]="links.trademarksList" [config]="textLinkConfig" [value]="trademarksListText"></ra-ui-static-text>
      </div>&nbsp;
    </div>
    <div class="about-content">
        <div class="copy-right rights-reserved copy-right-text">
            <ra-ui-static-text [value]="'\u00A9 ' + yearValue + raIncDescription" [config]="textConfig"></ra-ui-static-text>&nbsp;
            <ra-ui-static-text [value]="allRightsReservedText" [config]="textConfig"></ra-ui-static-text>
        </div>
    </div>
  </div>
</div>
