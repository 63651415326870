<div class="error-main-container">
  <div class="error-sub-container">
    <div class="logoImage">
      <ra-ui-image
        class="logo-Image"
        value="/assets/images/Logo.svg"
        [config]="configImg"
      >
      </ra-ui-image>
    </div>
    <div class="errorImage">
      <ra-ui-image
        class="error-Image"
        value="/assets/images/500-error.svg"
        [config]="configImg"
      >
      </ra-ui-image>
    </div>
    <div class="content-div">
      <div class="text-info">
        <ra-ui-static-text
          id="server-error"
          [value]="errorText"
          class="subtitle"
        >
        </ra-ui-static-text>
      </div>
      <div class="caption-text">
        <ra-ui-static-text
          id="caption"
          [value]="title"
          [disabled]="true"
          [config]="config"
        >
        </ra-ui-static-text>
      </div>
      <div class="home-button">
        <ra-ui-outlined-button
          label="Back home"
          id="back-home"
          [color]="themeColor.Primary"
          (click)="backHome()"
        >
        </ra-ui-outlined-button>
      </div>
    </div>
  </div>
</div>
