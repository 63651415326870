import { INodeList } from '../models/devicesList.model';

export class SearchUtils {
  public filterList(OriginalList: Array<any>, searchKey: string) {
    return OriginalList.filter(function (node) {
      return node.name.includes(searchKey);
    }).map(function (node) {
      return node;
    });
  }

  objectIteration(Obj: object): number {
    let totalNode: number = 0;
    const lengthObj: number = Object.keys(Obj).length || 0;
    Object.entries(Obj).forEach(([key, value], index) => {
      totalNode = Number(value) + totalNode;
      if (++index === lengthObj) {
        // this.calculatePercentage(totalNode, Obj["Error"]);
      }
    });
    return totalNode;
  }

  calculatePercentage(totalValue: number, errorValue: number): number {
    return (100 * errorValue) / totalValue;
  }

  calculateInstanceUpdates(instanceArray: Array<any>): number {
    let updateCount: number = 0;
    instanceArray.forEach((element) => {
      const updateRequired = element.manifestInfo.transitionAction;
      if (
        updateRequired === '1INSTANCE_TA_UPDATEREQUIRED' ||
        updateRequired === 'INSTANCE_TA_UPDATEWITHRESTART' ||
        updateRequired === 'INSTANCE_TA_UPDATEWITHPURGE'
      ) {
        updateCount += 1;
      }
    });
    return updateCount;
  }

  calculateEdgeAppUpdates(edgeAppArray: Array<any>): number {
    let updateCount: number = 0;
    edgeAppArray.forEach((element) => {
      const updateRequired = element.parentDetail.updateAvailable;
      if (updateRequired) {
        updateCount += 1;
      }
    });
    return updateCount;
  }

  addInstancesIntoArray(
    array: Array<any>,
    instanceList: Array<any>,
    property: 'appId' | 'deviceId',
    nodeList? : Array<any>
  ): Promise<Array<any>> {
    return new Promise<Array<any>>((resolve, reject) => {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        let instances = instanceList.filter((value) => value[property] === element.id);
        element.showAllInstances = false;
        if(element._searchInputMatchResult){
          delete element._searchInputMatchResult
        }
        if(nodeList){
          element['appInstances'] = this.addNodeVersionIntoAppInstanceList(instances,nodeList);
        }else{
          element['appInstances'] = instances;
        }
      }
      resolve(array);
    });
  }

  addNodeVersionIntoAppInstanceList(instanceArray:Array<any> , nodeArray:Array<any>):Array<any>{
    instanceArray.forEach(element => {
      let nodeDetail = nodeArray.find(obj => obj.id === element.deviceId)
      element['eveImageName'] = nodeDetail.eveImageName
      element['isEveLatest'] = nodeDetail.isEveLatest
    });
    return instanceArray
  }

}
