import { NgModule } from '@angular/core';
import { ErrorPagesRoutingModule } from './error-pages.routes';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { RaUiImageModule } from '@ra-web-tech-ui-toolkit/common-utils/image';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';

@NgModule({
  declarations: [
    InternalServerErrorComponent,
    PageNotFoundComponent,
    UnauthorizedComponent
  ],
  imports: [
    ErrorPagesRoutingModule,
    RaUiImageModule,
    RaUiStaticTextModule,
    RaUiButtonModule
  ],
  providers:[]
})
export class ErrorPagesModule { }
