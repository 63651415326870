<div *ngIf="!isDataLoading">
  <div *ngIf="edgeNodes.length !== 0">
    <mat-accordion
      class="example-headers-align"
      [multi]="true"
      *ngFor="let edgeNode of edgeNodes; let nodeindex = index"
    >
      <mat-expansion-panel
        [expanded]="false"
        hideToggle
        [ngClass]="edgeNode?.showAllInstances ? 'showAll' : 'showLess'"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="name-column">
            <ra-ui-icon-button
              [ngClass]="
                edgeNode.appInstances.length > 0
                  ? 'dropdown-show'
                  : 'dropdown-hide'
              "
              icon="ra-icon-ide-sm-caret-down"
              [config]="toggleConfig"
            ></ra-ui-icon-button
            >&nbsp;
            <ng-container [ngSwitch]="getStatus(edgeNode.runState)">
              <i
                *ngSwitchCase="'Online'"
                class="ra-icon-ide-sm-color-check"
              ></i>
              <i *ngSwitchCase="'Error'" class="ra-icon-ide-sm-color-error"></i>
              <i
                *ngSwitchCase="'Suspect'"
                class="ra-icon-ide-sm-color-warning"
              ></i>
              <img
                *ngSwitchCase="'Provisioned'"
                alt="Provisioned"
                src="/assets/icons/provisioned.svg"
              />
              <img
                *ngSwitchCase="'Unprovisioned'"
                alt="Unprovisioned"
                src="/assets/icons/unprovisioned.svg"
              />
              <i *ngSwitchDefault class="ra-icon-ide-sm-color-unknown"></i>
            </ng-container>
            &nbsp;
            <ra-ui-static-text
              [value]="getStatus(edgeNode.runState)"
            ></ra-ui-static-text>
          </mat-panel-title>
          <mat-panel-description class="name-column">
            <i class="ra-icon-ide-md-applications custom-application"></i>
            <ra-ui-static-text value="{{ edgeNode.name }}"></ra-ui-static-text>
          </mat-panel-description>
          <mat-panel-description class="OSversion">
            <i
              class="ra-icon-ide-md-color-warning custom-application"
              [ngClass]="
                edgeNode.isEveLatest == 'False' && edgeNode.eveImageName != ''
                  ? 'updates-show'
                  : 'updates-hide'
              "
            ></i>
            <ra-ui-static-text
              [value]="edgeNode.eveImageName"
            ></ra-ui-static-text>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- table -->
        <table mat-table [dataSource]="edgeNode.appInstances">
          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">
              <div class="statusCol name-column">
                <ng-container [ngSwitch]="getStatus(element.runState)">
                  <i
                    *ngSwitchCase="'Online'"
                    class="ra-icon-ide-sm-color-check"
                  ></i>
                  <i
                    *ngSwitchCase="'Error'"
                    class="ra-icon-ide-sm-color-error"
                  ></i>
                  <i
                    *ngSwitchCase="'Suspect'"
                    class="ra-icon-ide-sm-color-warning"
                  ></i>
                  <div
                    *ngSwitchCase="'Initialized'"
                    class="custom-info-icon"
                  ></div>
                  <i
                    *ngSwitchCase="'Unknown'"
                    class="ra-icon-ide-sm-color-unknown custom-unknown"
                  ></i>
                  <i *ngSwitchDefault class="ra-icon-ide-sm-color-unknown"></i>
                </ng-container>
                &nbsp;
                <ra-ui-static-text
                  value="{{ getStatus(element.runState) }}"
                ></ra-ui-static-text>
              </div>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="appInstanceName">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              <div class="name-column">
                <i class="ra-icon-ide-md-build-and-deploy custom-style"></i>
                <a class="redirect" (click)="urlZededaRedirect(element.id)">{{
                  element.name
                }}</a>
              </div>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="nodeName">
            <th mat-header-cell *matHeaderCellDef>Node Name</th>
            <td mat-cell *matCellDef="let element">
              <div class="name-column">
                <i
                  class="ra-icon-ide-md-color-error custom-application updates-hide"
                ></i>
                <ra-ui-static-text value="{{ edgeNode.name }}">
                </ra-ui-static-text>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-action-row *ngIf="edgeNode.appInstances.length > 8">
          <ra-ui-text-button
            [label]="buttonShowLessText"
            *ngIf="edgeNode.showAllInstances"
            (click)="edgeNode.showAllInstances = !edgeNode.showAllInstances"
            id="basicButton"
          ></ra-ui-text-button>
          <ra-ui-text-button
            [label]="buttonShowAllText"
            *ngIf="!edgeNode.showAllInstances"
            (click)="edgeNode.showAllInstances = !edgeNode.showAllInstances"
            id="basicButton"
          ></ra-ui-text-button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <div *ngIf="edgeNodes.length == 0">
    <div class="device-main-container">
      <div class="device-sub-container">
        <div class="deviceImage">
          <ra-ui-image
            class="device-img"
            value="../../assets/images/Connection.svg"
            [config]="configImg"
          >
          </ra-ui-image>
        </div>
        <div class="content-text">
          <ra-ui-static-text
            class="content"
            [value]="contentText"
            [config]="config"
          ></ra-ui-static-text>
        </div>
        <div class="add-edge-node">
          <ra-ui-outlined-button
            [label]="outlinedButtonText"
            id="add-node"
            [color]="themeColor.None"
            (onClick)="redirectUrl('edge-nodes/add')"
          >
          </ra-ui-outlined-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isDataLoading" class="loading-state">
  <app-loader></app-loader>
</div>
