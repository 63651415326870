import { Component, OnInit } from '@angular/core';
import { IToggleIconButton } from '@ra-web-tech-ui-toolkit/buttons/button-toggle';
import {
  IconSize,
  IInnerItem,
  NotificationType,
  TextAlign,
  ThemeColor,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  IStaticTextConfig,
  StaticTextConfig,
  TextAlignVertically,
} from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { IAppSearchList } from '../models/appList.model';
import { CommunicationService } from '../service/communication.service';
import { AppUtils } from '../utils/app-utils';
import {
  IImageConfig,
  ImageConfig,
} from '@ra-web-tech-ui-toolkit/common-utils/image';
import { edgeAppLabels } from 'src/app/constants';

@Component({
  selector: 'app-edge-apps',
  templateUrl: './edge-apps.component.html',
  styleUrls: ['./edge-apps.component.scss'],
})
export class EdgeAppsComponent implements OnInit {
  themeColor = ThemeColor;
  isDataLoading: boolean = true;
  vulnerabilitiesText : string = edgeAppLabels.vulnerabilitiesNotAvailable
  vulnerabilitiesToolTipText : string = edgeAppLabels.vulnerabilitiesToolTip
  buttonShowLessText : string = edgeAppLabels.buttonTextLabelShowLess
  buttonShowAllText : string = edgeAppLabels.buttonTextLabelShowAll
  contentText : string = edgeAppLabels.contentTextValue
  deployOutlinedButtonText : string = edgeAppLabels.deployOulinedButtonTextLabel
  importOutlinedButtonText : string = edgeAppLabels.importOulinedButtonTextLabel
  config: IStaticTextConfig = new StaticTextConfig({
    allowMultiline: true,
    textAlign: TextAlign.Center,
    textAlignVertically: TextAlignVertically.Center,
  });
  configImg: IImageConfig = new ImageConfig({
    disablePadding: true,
  });

  items: IToggleIconButton[] = [
    {
      icon: 'ra-icon-ide-sm-caret-down',
      id: 'dropDownSmall',
      usePaneIndicator: false,
    },
  ];
  statusIcons: IInnerItem[] = [
    { value: NotificationType.Info },
    { value: NotificationType.Success },
    { value: NotificationType.Error },
    { value: NotificationType.Warning },
  ];
  activeStatusIcon = <NotificationType>this.statusIcons[1].value;
  toggleConfig = {
    iconSize: IconSize.S,
    useInvertedMode: false,
    useAltDisabledColor: false,
  };
  displayedColumns: string[] = ['status', 'name', 'nodeName', 'nodeVersion'];
  edgeApps: Array<IAppSearchList> = [];
  constructor(private onChangeService: CommunicationService) {
    this.onChangeService.getEdgeAppSearchChanges$.subscribe({
      next: (response: Array<IAppSearchList>) => {
        this.edgeApps = response;
      },
    });
  }

  ngOnInit(): void {
    this.checkLoading();
  }

  getStatus(runState: string) {
    return AppUtils.getStatusString(runState);
  }

  urlZededaRedirect(appInstanceId: string) {
    this.onChangeService.redirectTo(
      'edge-app-instances/' + appInstanceId + '/details/status'
    );
  }

  redirectUrl(url:string){
    this.onChangeService.redirectTo(url);
  }

  checkLoading() {
    this.onChangeService.getLoaderChanges$.subscribe((isLoading: boolean) => {
      this.isDataLoading = isLoading;
    });
  }
}
