import { Component, OnInit } from '@angular/core';
import { IIconButtonConfig } from '@ra-web-tech-ui-toolkit/buttons/button';
import { IconSize } from '@ra-web-tech-ui-toolkit/cdk/types';
import { IProjectSelected } from '../models/project.model';
import { CommunicationService } from '../service/communication.service';
import { DividerOrientation } from '@ra-web-tech-ui-toolkit/common-utils/divider';
import { detailsHeadingText } from 'src/app/constants';

@Component({
  selector: 'app-details-heading',
  templateUrl: './details-heading.component.html',
  styleUrls: ['./details-heading.component.scss'],
})
export class DetailsHeadingComponent implements OnInit {
  projectName: string = '';
  projectDashboardIconToolTipText : string = detailsHeadingText.projectDashboardIconToolTip
  projectInfoIconToolTipText : string = detailsHeadingText.projectInfoIconToolTip
  orientation:DividerOrientation = DividerOrientation.vertical
  constructor(private onChangeService: CommunicationService) {}
  iconConfig : IIconButtonConfig = {
    tooltipConfig: { position: 'Top' },
    iconSize : IconSize.M
  };

  ngOnInit(): void {
    this.onChangeService.getProjectChanges$.subscribe(
      (projectObj: IProjectSelected) => {
        this.projectName = projectObj.name;
      }
    );
  }

  sendUrl(value: string) {
    this.onChangeService.redirectTo(value);
  }
}
