import { Injectable } from '@angular/core';
import {
  CanActivate,
} from '@angular/router';

@Injectable()
export class ReRouteGuard implements CanActivate {
  constructor() {}

  canActivate(): boolean {
    sessionStorage.setItem('homeUrl',"/provision")
    return true
 }
}
