<div class="card-container">
  <!-- Nodes -->
  <div class="node-details" *ngIf="!isDataLoading">
    <div *ngIf="nodeCount != 0" class="status-card">
      <div class="node-count">
        <div class="bold">{{nodeCount}}</div>
        <strong class="sub-card-text">Nodes</strong>
      </div>
      <div class="divider"></div>
      <div class="status">
        <div class="online-text">
          <ra-ui-status-icon
            id="statusIcon"
            [icon]="activeStatusIcon"
          ></ra-ui-status-icon
          ><ra-ui-static-text id="statusText" [value]="onlineText"></ra-ui-static-text>
        </div>
        <strong>/ {{nodeOnlinePercentage}}%</strong>
      </div>
    </div>
    <div *ngIf="nodeCount == 0">
      <div class="status-card-image-No-Node cursor-pointer" (click)="sendUrl('edge-nodes/add')">
        <img src="assets/images/ConnectionSmall.svg" class="node-image" alt="Empty Node" />
        <div class="add-card-text">
          <i class="ra-icon-ide-md-plus"></i>
          <ra-ui-static-text [value]="addEdgeNodeText" [config]="textConfig"></ra-ui-static-text>
        </div>
      </div>
    </div>
  </div>

  <!-- Loading -->
  <div class="node-details" *ngIf="isDataLoading">
    <div>
      <div class="status-card-image">
        <div>
          <app-loader></app-loader>
        </div>
      </div>
    </div>
  </div>
  <!-- End Nodes -->

  <!-- Instances -->
  <div class="node-details" *ngIf="!isDataLoading">
    <div *ngIf="nodeCount != 0 && instanceCount != 0" class="status-card">
      <div class="node-count">
        <div class="bold">{{instanceCount}}</div>
        <strong class="sub-card-text">Instances</strong>
      </div>
      <div class="divider"></div>
      <div class="multi-status">
        <div class="status">
          <div class="online-text">
            <i class="ra-icon-ide-md-color-alarm-urgent-priority"></i>
            <ra-ui-static-text id="statusText" [value]="vulnerabilitiesText"></ra-ui-static-text>
          </div>
          <strong>/ NA</strong>
        </div>
        <div class="status">
          <div class="online-text">
            <i class="ra-icon-ide-md-color-warning"></i>
            <ra-ui-static-text id="statusText" [value]="updateText"></ra-ui-static-text>
          </div>
          <strong>/ {{instanceUpdateCount}}</strong>
        </div>
      </div>
    </div>
    <div *ngIf="nodeCount != 0 && instanceCount == 0">
      <div class="instance-status-card-image instance-card cursor-pointer" (click)="sendUrl('edge-app-instances/add')">
        <img src="assets/images/EmptyInstance.svg" class="node-image"  alt="Active Instance" />
        <div class="add-card-text">
          <i class="ra-icon-ide-md-plus"></i>
          <ra-ui-static-text [value]="addAppInstanceText" [config]="textConfig"></ra-ui-static-text>
        </div>
      </div>
    </div>
    <div *ngIf="nodeCount == 0">
      <div class="status-card-image">
        <img src="assets/images/EmptyInstance.svg" alt="Empty Node" />
        <div>
          <ra-ui-text-button
            icon="ra-icon-ide-md-plus"
            [disabled]="true"
            [label]="addAppInstanceText"
            id="basicButton"
            [color]="buttonColor"
          >
          </ra-ui-text-button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Instances -->

  <!-- Edge Apps -->
  <div class="node-details" *ngIf="!isDataLoading">
    <div *ngIf="appCount != 0" class="status-card">
      <div class="node-count">
        <div class="bold">{{appCount}}</div>
        <strong class="sub-card-text">Edge apps</strong>
      </div>
      <div class="divider"></div>
      <div class="multi-status">
        <div class="status">
          <div class="online-text">
            <i class="ra-icon-ide-md-color-alarm-urgent-priority"></i>
            <ra-ui-static-text id="statusText" [value]="vulnerabilitiesText"></ra-ui-static-text>
          </div>
          <strong>/ NA</strong>
        </div>
        <div class="status">
          <div class="online-text">
            <i class="ra-icon-ide-md-color-warning"></i>
            <ra-ui-static-text id="statusText" [value]="updateText"></ra-ui-static-text>
          </div>
          <strong>/ {{edgeAppUpdateCount}}</strong>
        </div>
      </div>
    </div>
  </div>
  <!-- End Edge Apps -->
</div>
