import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthDashboardGuard {
  constructor(private router : Router) {}
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(environment.appConfiguration.activateDashboard){
      return true;
    }else{
      this.router.navigateByUrl("error/401")
      return false
    }
  }
}
