<div class="entitlement-dialog">
    <div class="entitlement-title">
      <ra-ui-status-icon
        id="statusIcon"
        [icon]="activeStatusIcon"
      ></ra-ui-status-icon
      ><ra-ui-static-text
        [value]="entitlementNotPurchasedText"
      ></ra-ui-static-text>
    </div>
    <div class="dialog-sub-content">
      <ra-ui-static-text
        [value]="redirectToFTHubText"
      ></ra-ui-static-text>
    </div>
    <div class="entitlement-buttons">
      <div>
        <ra-ui-main-button
          [label]="goToFactoryTalkHubButtonLabel"
          id="redirect-entitlement"
          class="save-button"
          (onClick)="onRedirect()"
        >
        </ra-ui-main-button>
      </div>
      <div>
        <ra-ui-outlined-button
          [label]="cancelButtonLabel"
          id="cancel-entitlement"
          (onClick)="onCancel()"
        >
        </ra-ui-outlined-button>
      </div>
    </div>
</div>
