<div *ngIf="!isDataLoading">
  <div *ngIf="edgeApps.length !== 0">
    <mat-accordion
      class="example-headers-align"
      *ngFor="let edgeApp of edgeApps"
      [multi]="true"
    >
      <mat-expansion-panel
        [expanded]="false"
        hideToggle
        [ngClass]="edgeApp?.showAllInstances ? 'showAll' : 'showLess'"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="appStatus">
            <ra-ui-icon-button
              [ngClass]="
                edgeApp.appInstances.length > 0
                  ? 'dropdown-show'
                  : 'dropdown-hide'
              "
              icon="ra-icon-ide-sm-caret-down"
              [config]="toggleConfig"
            ></ra-ui-icon-button
            >&nbsp;
            <ra-ui-status-icon
              class="statusIcon edgeAppStatus"
              id="statusIcon"
              [config]="toggleConfig"
              [icon]="activeStatusIcon"
            ></ra-ui-status-icon>
            &nbsp;
            <ra-ui-static-text
              [value]="vulnerabilitiesText"
              [disabled]="true"
              [raUiTooltip]="vulnerabilitiesToolTipText"
            ></ra-ui-static-text>
          </mat-panel-title>
          <mat-panel-description class="edgeApp name-column">
            <i class="ra-icon-ide-md-applications custom-application"></i>
            <ra-ui-static-text [value]="edgeApp.name"> </ra-ui-static-text>
          </mat-panel-description>
          <mat-panel-description class="OSversion">
            <i
              class="ra-icon-ide-md-color-warning custom-application"
              [ngClass]="
                edgeApp?.parentDetail?.updateAvailable
                  ? 'updates-show'
                  : 'update-hide'
              "
            ></i>
            <ra-ui-static-text
              *ngIf="edgeApp.userDefinedVersion != ''"
              [value]="
                (edgeApp.userDefinedVersion | versionPipe).transformedValue
              "
              [disabled]="(edgeApp.userDefinedVersion | versionPipe).disabled"
            ></ra-ui-static-text>
          </mat-panel-description>
          <mat-panel-description class="OSversion"> </mat-panel-description>
        </mat-expansion-panel-header>

        <!-- table -->
        <table mat-table [dataSource]="edgeApp?.appInstances || []">
          <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>No.</th>
            <td mat-cell *matCellDef="let element">
              <div class="statusCol name-column">
                <ng-container [ngSwitch]="getStatus(element.runState)">
                  <i
                    *ngSwitchCase="'Online'"
                    class="ra-icon-ide-sm-color-check"
                  ></i>
                  <i
                    *ngSwitchCase="'Error'"
                    class="ra-icon-ide-sm-color-error"
                  ></i>
                  <i
                    *ngSwitchCase="'Suspect'"
                    class="ra-icon-ide-sm-color-warning"
                  ></i>
                  <div
                    *ngSwitchCase="'Initialized'"
                    class="custom-info-icon"
                  ></div>
                  <i
                    *ngSwitchCase="'Unknown'"
                    class="ra-icon-ide-sm-color-unknown custom-unknown"
                  ></i>
                  <i *ngSwitchDefault class="ra-icon-ide-sm-color-unknown"></i>
                </ng-container>
                &nbsp;
                <ra-ui-static-text
                  [value]="getStatus(element.runState)"
                ></ra-ui-static-text>
              </div>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" class="app-instance-column">
              <div class="name-column">
                <i class="ra-icon-ide-md-build-and-deploy custom-style"></i>
                <a class="redirect" (click)="urlZededaRedirect(element.id)">{{
                  element.name
                }}</a>
              </div>
            </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="nodeName">
            <th mat-header-cell *matHeaderCellDef>Node Name</th>
            <td mat-cell *matCellDef="let element">
              <div class="name-column">
                <i class="ra-icon-ide-sm-device custom-application"></i>
                <ra-ui-static-text value="{{ element.deviceName }}">
                </ra-ui-static-text>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="nodeVersion">
            <th mat-header-cell *matHeaderCellDef>Node Version</th>
            <td mat-cell *matCellDef="let element">
              <div class="name-column">
                <i
                  class="ra-icon-ide-md-color-warning custom-application"
                  [ngClass]="
                    element.isEveLatest == 'False' && element.eveImageName != ''
                      ? 'updates-show'
                      : 'update-hide'
                  "
                ></i>
                <ra-ui-static-text
                  value="{{ element.eveImageName }}"
                ></ra-ui-static-text>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-action-row *ngIf="edgeApp.appInstances.length > 8">
          <ra-ui-text-button
            [label]="buttonShowLessText"
            *ngIf="edgeApp.showAllInstances"
            (click)="edgeApp.showAllInstances = !edgeApp.showAllInstances"
            id="basicButton"
          ></ra-ui-text-button>
          <ra-ui-text-button
            [label]="buttonShowAllText"
            *ngIf="!edgeApp.showAllInstances"
            (click)="edgeApp.showAllInstances = !edgeApp.showAllInstances"
            id="basicButton"
          ></ra-ui-text-button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- No Edge App div -->
  <div *ngIf="edgeApps.length == 0">
    <div class="device-main-container">
      <div class="device-sub-container">
        <div class="deviceImage">
          <ra-ui-image
            class="device-img"
            value="../../assets/images/APP_lg.svg"
            [config]="configImg"
          >
          </ra-ui-image>
        </div>
        <div class="content-text">
          <ra-ui-static-text
            class="content"
            [value]="
              contentText
            "
            [config]="config"
          ></ra-ui-static-text>
        </div>
        <div class="add-edge-app">
          <ra-ui-outlined-button
            [label]="deployOutlinedButtonText"
            id="deploy-app"
            [color]="themeColor.None"
            (onClick)="redirectUrl('edge-app-instances/list')"
          >
          </ra-ui-outlined-button>
          <ra-ui-outlined-button
            [label]="importOutlinedButtonText"
            id="import-app"
            [color]="themeColor.None"
            (onClick)="redirectUrl('marketplace/edge-apps/store/list')"
          >
          </ra-ui-outlined-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isDataLoading" class="loading-state">
  <app-loader></app-loader>
</div>
