import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizationService } from './shared/utils/services/authorization.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'eaas-hub';

  constructor(
    public translate:TranslateService,
    public route: ActivatedRoute,
    public sharedService: AuthorizationService,
   ) {
    translate.addLangs(['en', 'cz','zh-cn']);

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang() || '';
    translate.use(browserLang.match(/en|cz|zh-cn/) ? browserLang : 'en');
    this.translate.use('en');
   }


  ngOnInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      const tenantId = params.get('tenantId');
      if (tenantId) {
        this.sharedService.setTenantId(tenantId)
      }
    });
  }

}
