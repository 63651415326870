import { Component, OnInit, ViewChild } from '@angular/core';
import {
  IToastConfig,
  ToastConfig,
  ToastDirective,
} from '@ra-web-tech-ui-toolkit/popups/toast';
import { IMessage } from 'src/app/shared/utils/common-models/message.model';
import { AuthorizationService } from 'src/app/shared/utils/services/authorization.service';
import {
  NavBarTheme,
  NavItem,
  NavItemActions,
  Organization,
  UserNotification,
} from '@rockwell-automation-inc/layout';
import {
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GainsightService } from 'src/app/shared/utils/services/gainsight.service';
import {
  AuthService,
  CommonService,
  HubConnectionProxy,
  SignalrService,
  UserProfile,
} from '@rockwell-automation-inc/service';
import { SharedMessageService } from 'src/app/shared/utils/services/shared-message.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, } from '@angular/material/dialog';
import { CommunicationService } from '../service/communication.service';
import { AboutDialogComponent } from '../about-dialog/about-dialog.component';
import { IBannerConfig } from '@ra-web-tech-ui-toolkit/popups/banner';
import { NotificationType } from '@ra-web-tech-ui-toolkit/cdk/types';
import { HttpErrorResponse } from '@angular/common/http';
import { dashboardLayoutText } from 'src/app/constants';

export enum HelpMenuItem {
  About = 'HelpAbout',
}
@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit {
  public onSideNavChange: boolean = false;
  categories: NavItem[] = [];
  collapsedMenuCategories: string[] = [];
  tempTenantId = sessionStorage.getItem('tenantId') || '';
  tempOldTenantId = sessionStorage.getItem('oldTenantId') || '';
  tenantIdSubject$ = new BehaviorSubject<string>(this.tempTenantId);
  logoTypeTheme = NavBarTheme.LIGHT;
  tenantId$ = new Subject<string>();
  bannerMessage: string = dashboardLayoutText.bannerMessageText
  bannerConfig: IBannerConfig = {
    showDefaultIcon: true,
    showCloseIcon: false,
    useNotificationBackground: false,
    useSingleLineMessage: false,
    notificationType: NotificationType.Error,
  };
  bannerRaIcon: string = 'ra-icon-ide-sm-color-error';
  // tenantId$ = this.tenantIdSubject$
  //   .asObservable()
  //   .pipe(
  //     filter(
  //       (tenantId) =>
  //         tenantId !== '' || tenantId !== null || tenantId !== undefined
  //     )
  //   );

  getAccountMenuData$: any;
  // Notifications
  notificationAction$?: Observable<any>;
  orgs$!: Observable<Organization[]>;
  allOrgs: Organization[] = [];
  orgName: string = '';
  getCurrentTenantNameSubject$ = new Subject<string>();
  // getCurrentTenantName$ = this.commonService.getCurrentTenantName$();
  // this.getCurrentTenantNameSubject$.asObservable()
  //   .pipe(
  //     filter(
  //       (currentTenantName) =>
  //         currentTenantName !== '' || currentTenantName !== null
  //     )
  //   );
  @ViewChild(ToastDirective, { static: true }) toastRef!: ToastDirective;

  helpItems: NavItem[] = [
    {
      displayName: dashboardLayoutText.helpItemDisplayText,
      id: HelpMenuItem.About,
      action: NavItemActions.custom,
    },
  ];

  dialogConfig: MatDialogConfig = {
    maxWidth: 556,
    panelClass: 'about-panel-class',
    disableClose: true
  };
  error!: HttpErrorResponse;
  isEdgeApiDown: boolean = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private sharedService: AuthorizationService,
    private gainsightService: GainsightService,
    private messageService: SharedMessageService,
    private commonService: CommonService,
    public signalRService: SignalrService,
    private dialog: MatDialog,
    private onChangeService: CommunicationService
  ) {
    this.authService.isAuthenticated$
      .pipe(
        skipWhile((it) => it.valueOf() === false),
        switchMap(() => this.authService.user$),
        take(1),
        distinctUntilChanged((previous: any, current: any) => {
          return JSON.stringify(previous) === JSON.stringify(current);
        }),
        tap((userProfile: UserProfile) => {
          if (userProfile) {
            let currentOrgName = '';
            this.gainsightService.initGainsight(userProfile);
            this.commonService.setUserId(userProfile.userId);
            this.commonService.setCurrentTenantId(this.tempTenantId);
            this.commonService.initializeNavbar$('').subscribe();
            this.commonService
              .getOrganizations$()
              .subscribe((array: Array<any>) => {
                this.allOrgs = array;
                if (array.length !== 0) {
                  const newArray = array.filter((value: any) => {
                    return value.id === this.tempTenantId;
                  });
                  currentOrgName = newArray[0].name;
                  this.sharedService.setCurrentOrgName(currentOrgName);
                  this.getCurrentTenantNameSubject$.next(currentOrgName);
                }
              });
            this.commonService.getAccountMenuData$().subscribe((response) => {
              this.getAccountMenuData$ = response;
            });
            this.orgs$ = this.commonService.getOrganizations$();
          }
        })
      )
      .subscribe();
  }

  toastConfig: IToastConfig = new ToastConfig({
    useSingleLineMessage: false,
    disableStackMultiple: true,
    disableCloseButton: false,
    delay: 8000,
  });

  ngOnInit(): void {
    this.messageService.$messageHandler.subscribe((message: IMessage) => {
      this.displayToast(message);
    });
    this.getCurrentTenantNameSubject$.pipe().subscribe((name: string) => {
      if (name !== '') {
        this.orgName = name;
        this.sendOrgDetails(this.tempTenantId);
      }
    });

    this.onChangeService
      .getOldTenantIdSubject$
      .subscribe((oldTenantId: string) => {
        this.tempOldTenantId = oldTenantId;
        this.commonService.setCurrentTenantId(this.tempOldTenantId);
        this.changeOrg({ tenantId: this.tempOldTenantId });
      });

    this.messageService.getErrorsStatus$.subscribe(
      (errorCode: number | undefined) => {
        if (errorCode === 503) {
          this.isEdgeApiDown = true;
        } else {
          this.isEdgeApiDown = false;
        }
      }
    );
  }

  changeOrg(event: any): void {
    const oldTenantId = this.sharedService.readTenantId();
    const oldOrgName = this.sharedService.readCurrentOrgName();
    this.sharedService.setOldTenantId(oldTenantId);
    this.sharedService.setOldOrgName(oldOrgName);
    this.commonService.setCurrentTenantId(event.tenantId);
    this.tempTenantId = event.tenantId || '';
    this.sharedService.setTenantId(event.tenantId);
    const orgObj = this.allOrgs.filter((value: any) => {
      return value.id === this.tempTenantId;
    });
    if (orgObj[0].name) {
      this.getCurrentTenantNameSubject$.next(orgObj[0].name);
    }
    this.sharedService.setCurrentOrgName(orgObj[0].name);
    // let urlTree = this.router.parseUrl(this.router.url);
    // if(urlTree.queryParams['tenantId']){
    //     urlTree.queryParams['tenantId'] = event.tenantId;
    // }
    // urlTree.queryParams['tenantId'] = event.tenantId;
    // this.router.navigateByUrl(urlTree);
  }

  sendOrgDetails(tenantId: string) {
    this.onChangeService.tenentChange(tenantId, this.orgName);
  }

  // Notifications
  getUnreadNotificationsCount$ =
    this.commonService.getUnreadNotificationsCount$();

  getUserNotifications$ = this.commonService
    .getUserNotifications$()
    .pipe(map((data) => data.records));

  getNotifications$ = this.commonService.getNotifications$();

  refreshNotificationsGrid$ = this.commonService
    .refreshNotificationsGrid$()
    .pipe(
      switchMap(() => this.commonService.getUserNotificationCounts$()),
      switchMap(() => this.commonService.getUserNotifications$())
    );

  getUserNotificationCounts$ = this.commonService.getUserNotificationCounts$();

  startSignalRConnection$ = this.authService.isAuthenticated$.pipe(
    filter((isAuthenticated) => isAuthenticated === true),
    tap(() => {
      this.signalRService.startConnection();
    })
  );

  onHubConnected$ = this.signalRService.onHubConnected$.pipe(
    tap((hubProxy) => {
      this.onSignalRHubConnected(hubProxy);
    })
  );

  signalRUserNotifications$ = this.signalRService.userNotification$.pipe(
    withLatestFrom(
      this.commonService.getNotifications$(),
      this.commonService.getUnreadNotificationsCount$()
    ),
    tap(([notificationOnSignalR, notifications, unreadNotificationCount]) => {
      const existingNotification = notifications.find(
        (notification) => notification.id === notificationOnSignalR.id
      );

      if (!existingNotification && notificationOnSignalR.isRead === false) {
        const updatedNotifications = [
          notificationOnSignalR,
          ...notifications,
        ].slice(0, 10);
        this.commonService.setNotification(updatedNotifications);
        this.commonService.setUnreadNotificationsCount(
          (unreadNotificationCount += 1)
        );
      }

      if (
        existingNotification?.isRead === false &&
        notificationOnSignalR.isRead === true
      ) {
        const updatedNotifications = notifications.map((notification) => {
          if (notification.id === notificationOnSignalR.id) {
            notification.isRead = notificationOnSignalR.isRead;
            return notification;
          }
          return notification;
        });
        this.commonService.setNotification(updatedNotifications);
      }

      if (notificationOnSignalR.isRead === true) {
        this.commonService.setUnreadNotificationsCount(
          (unreadNotificationCount -= 1)
        );
      }
    })
  );

  notificationService$ = this.commonService.getUserNotifications$.bind(
    this.commonService
  );

  onSignalRHubConnected(hubProxy: HubConnectionProxy): void {
    hubProxy.on('Notify', (userNotification: UserNotification) => {});
  }

  notificationAction(data: any): void {
    this.notificationAction$ =
      this.commonService.handleNotificationAction$(data);
  }

  logout() {
    this.authService.logout();
  }

  displayToast(toastMessage: IMessage) {
    if (toastMessage.showButtons) {
      this.toastRef.buttons = [{ label: 'Dismiss' }];
    } else {
      this.toastRef.buttons = [];
    }
    this.toastRef.addToast(
      toastMessage.message,
      toastMessage.type,
      toastMessage.title
    );
  }

  // Setting on logo click
  homeClick() {
    this.router.navigate(['/dashboard']);
  }

  openMessageDialog() {
    this.dialog.open(AboutDialogComponent, this.dialogConfig);
  }

  helpItemClicked(event: NavItem) {
    switch (event.id) {
      case HelpMenuItem.About: {
        this.openMessageDialog();
        break;
      }
    }
  }
}
