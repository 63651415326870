import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NotificationType,
  ThemeColor,
} from '@ra-web-tech-ui-toolkit/cdk/types';

export interface IRedirectType {
  redirectTo: string;
}

@Component({
  templateUrl: './redirect-dialog.component.html',
  styleUrls: ['./redirect-dialog.component.scss'],
})
export class RedirectDialogComponent {
  @Output() buttonClick = new EventEmitter<IRedirectType>();
  activeStatusIcon = NotificationType.Info;
  buttonColor = ThemeColor.Primary;

  constructor(
    public dialogRef: MatDialogRef<RedirectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRedirectType
  ){}

  ButtonClicked(type:string) {
    this.data.redirectTo = type;
    this.dialogRef.close()
  }
}
