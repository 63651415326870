import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  NotificationType,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  DialogService,
  IDialogConfig,
} from '@ra-web-tech-ui-toolkit/popups/dialog';
import { Subject } from 'rxjs';
import { ServerErrors } from 'src/app/constants';
import { IMessage } from '../common-models/message.model';
import { AuthorizationService } from './authorization.service';
import { EntitlementComponent } from './entitlement/entitlement.component';

@Injectable({ providedIn: 'root' })
export class SharedMessageService {
  dialogConfig: MatDialogConfig = {
    maxWidth: 556,
    panelClass: 'about-panel-class',
    disableClose: true,
  };
  entitlementDialogConfig: MatDialogConfig = {
    width: "520",
    panelClass: 'entitlement-dialog-class',
    disableClose: true,
  };
  dialogRef!: any;
  config!: IDialogConfig;
  public lastClickedButton: any;

  constructor(
    private window: Window,
    public dialogService: DialogService,
    public sharedService: AuthorizationService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  $messageHandler = new Subject<IMessage>();
  errorCodeSubject = new Subject<number | undefined>();
  getErrorsStatus$ = this.errorCodeSubject.asObservable();

  getErrors(error: HttpErrorResponse) {
    switch (error.status) {
      case 400:
        this.handleError(error);
        break;
      case 405:
        this.openNoEntitlementDialog();
        break;
      case 401:
        this.handleError(error);
        this.errorPageRedirection('401');
        break;
      case 403:
        this.handleError(error);
        break;
      case 404:
        this.handleError(error, ServerErrors.notFountServerError.title);
        break;
      case 500:
        this.handleError(error, ServerErrors.internalServerError.title);
        this.errorPageRedirection('500', error);
        break;
      case 503:
        this.getErrorsStatus(error);
        break;
      case 0:
        this.handleError(error, error.statusText);
        break;
    }
  }

  getErrorsStatus(error: HttpErrorResponse) {
    this.errorCodeSubject.next(error.status);
  }

  handleError(error: HttpErrorResponse, internalError?: string) {
    const message: IMessage = {
      message: internalError ? internalError : error.message,
      // title:internalError ? internalError : "Status: " + error.status.toString(),
      type: NotificationType.Error,
      showButtons: true,
    };
    this.$messageHandler.next(message);
  }

  errorPageRedirection(
    pageCode: '404' | '401' | '500',
    error?: HttpErrorResponse
  ) {
    if(pageCode === '401'){
      this.router.navigateByUrl('error/' + pageCode);
    }else if (error?.url?.includes('dashboard')) {
      this.router.navigateByUrl('error/' + pageCode);
    }
  }

  openNoEntitlementDialog() {
    if(this.dialog.openDialogs.length < 2){
      this.dialog.open(EntitlementComponent, this.entitlementDialogConfig);
    }
  }

  showToastMessage(message: IMessage) {
    this.$messageHandler.next(message);
  }
}
