export class AppUtils {

  static getStatusString(runState: string) {
    switch (runState) {
      case 'RUN_STATE_ONLINE':
        return 'Online';
      case 'RUN_STATE_INIT':
        return 'Initialized';
      case 'RUN_STATE_HALTED':
        return 'Halted';
      case 'RUN_STATE_REBOOTING':
        return 'Rebooting';
      case 'RUN_STATE_OFFLINE':
        return 'Offline';
      case 'RUN_STATE_UNKNOWN':
        return 'Unknown';
      case 'RUN_STATE_UNPROVISIONED':
        return 'Unprovisioned';
      case 'RUN_STATE_PROVISIONED':
        return 'Provisioned';
      case 'RUN_STATE_SUSPECT':
        return 'Suspect';
      case 'RUN_STATE_DOWNLOADING':
        return 'Downloading';
      case 'RUN_STATE_RESTARTING':
        return 'Restarting';
      case 'RUN_STATE_PURGING':
        return 'Purging';
      case 'RUN_STATE_HALTING':
        return 'Halting';
      case 'RUN_STATE_ERROR':
        return 'Error';
      case 'RUN_STATE_VERIFYING':
        return 'Verifying';
      case 'RUN_STATE_LOADING':
        return 'Loading';
      case 'RUN_STATE_CREATING_VOLUME':
        return 'Creating Volume';
      case 'RUN_STATE_BOOTING':
        return 'Booting';
      case 'RUN_STATE_MAINTENANCE_MODE':
        return 'Maintenance';
      default:
        return 'Other';
    }
  }

}
