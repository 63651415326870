import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
@Component({
  selector: 'app-callback-redirect',
  templateUrl: './callback-redirect.component.html',
  styleUrls: ['./callback-redirect.component.scss']
})
export class CallbackRedirectComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
    const url = sessionStorage.getItem('homeUrl') || "/dashboard";
    this.redirectTo(url)
  }

  redirectTo(url:string){
    this.router.navigate([url])
  }

}
