<div class="product-header margin-bottom">
  <div class="title">
    <ra-ui-static-text
      [value]="selectedOrganisation"
      class="product-title"
      [config]="TextConfigTitle"
    >
    </ra-ui-static-text>
  </div>
  <div class="add-project">
    <ra-ui-divider [orientation]="orientation"></ra-ui-divider>
    <ra-ui-icon-button
      icon="ra-icon-ide-md-plus"
      id="basicButton"
      [config]="{
        useInvertedMode: useInvertedMode,
        useAltDisabledColor: useAltDisabledColor
      }"
      [matMenuTriggerFor]="menu.matMenuRef"
      [raUiMenuTrigger]="menu"
      #menuTrigger="matMenuTrigger"
    >
    </ra-ui-icon-button>
    <ra-ui-menu
    id="explicitMenu"
    [items]="items"
    [menuTrigger]="menuTrigger"
    #menu
    (onClick)="ItemClick($event)"
    ></ra-ui-menu>
  </div>
</div>
