import { Pipe, PipeTransform } from '@angular/core';
import versionValue from '../data/version-value.json';
import { edgeAppLabels } from 'src/app/constants';

@Pipe({
  name: 'versionPipe',
})
export class VersionPipe implements PipeTransform {
  transform(value: string): { transformedValue: string; disabled: boolean } {
    if (versionValue.includes(value)) {
      return { transformedValue: edgeAppLabels.versionNotAvailableValue, disabled: true };
    }

    return { transformedValue: 'Version: ' + value, disabled: false };
  }
}
