import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '@rockwell-automation-inc/layout';
import { AuthorizationService } from '../shared/utils/services/authorization.service';
import { AuthGuardService, AuthService } from '@rockwell-automation-inc/service';
import { RaUiListModule } from '@ra-web-tech-ui-toolkit/lists/list';
import { RaUiTreeModule } from '@ra-web-tech-ui-toolkit/lists/tree';
import { RaUiToastModule } from '@ra-web-tech-ui-toolkit/popups/toast';
import { ProductHeaderComponent } from './product-header/product-header.component';
import { RaUiStaticTextModule } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { MainDetailComponent } from './main-detail/main-detail.component';
import { RaUiButtonModule } from '@ra-web-tech-ui-toolkit/buttons/button';
import { RaUiMenuModule } from '@ra-web-tech-ui-toolkit/popups/menu';
import { RaUiMainDetailContentModule } from '@ra-web-tech-ui-toolkit/common-views/main-detail-content';
import { RaUiBadgeModule } from '@ra-web-tech-ui-toolkit/indicators/badge';
import { RaUiButtonToggleModule } from '@ra-web-tech-ui-toolkit/buttons/button-toggle';
import { ProjectListComponent } from './project-list/project-list.component';
import { EdgeNodesComponent } from './edge-nodes/edge-nodes.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RaUiStatusIconModule } from '@ra-web-tech-ui-toolkit/common-utils/status-icon';
import { SubActiveGuard } from '../shared/utils/guard/route.guard';
import { MatTableModule } from '@angular/material/table';
import { EdgeAppsComponent } from './edge-apps/edge-apps.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RaUiInputModule } from '@ra-web-tech-ui-toolkit/form-system/input';
import { RaUiSearchInputModule } from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { RaUiSelectModule } from '@ra-web-tech-ui-toolkit/form-system/select';
import { CardDetailsComponent } from './card-details/card-details.component';
import { DetailsHeadingComponent } from './details-heading/details-heading.component';
import { RedirectService } from '../auth/service/redirect.service';
import { RaUiImageModule } from '@ra-web-tech-ui-toolkit/common-utils/image';
import { RaUiLoadingSpinnerModule } from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';
import { LoaderComponent } from './utils/loader/loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { RaUiTooltipModule } from '@ra-web-tech-ui-toolkit/common-utils/tooltip';
import { RaUiNavigationTabModule } from '@ra-web-tech-ui-toolkit/navigation/navigation-tab';
import { RaUiNavTabItemModule } from '@ra-web-tech-ui-toolkit/navigation/cdk';
import { RaUiBannerModule } from '@ra-web-tech-ui-toolkit/popups/banner';
import { AuthDashboardGuard } from './utils/guard/route.guard';
import {RaUiDividerModule} from '@ra-web-tech-ui-toolkit/common-utils/divider';
import { VersionPipe } from './utils/pipes/version.pipe';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    canActivate:[SubActiveGuard,AuthGuardService,AuthDashboardGuard],
    children: [
      {
        path: '',
        component: MainDetailComponent,
      },
    ],
  },
  {
    path : "error",
    loadChildren: () => import('../error-pages/error-pages.module').then(m => m.ErrorPagesModule)
  }
];

@NgModule({
  declarations: [
    DashboardLayoutComponent,
    ProductHeaderComponent,
    MainDetailComponent,
    ProjectListComponent,
    EdgeNodesComponent,
    EdgeAppsComponent,
    SearchFilterComponent,
    CardDetailsComponent,
    DetailsHeadingComponent,
    LoaderComponent,
    AboutDialogComponent,
    VersionPipe,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    RaUiListModule,
    RaUiTreeModule,
    RaUiStaticTextModule,
    RaUiToastModule,
    RaUiButtonModule,
    RaUiMenuModule,
    RaUiMainDetailContentModule,
    RaUiBadgeModule,
    RaUiStatusIconModule,
    RaUiButtonToggleModule,
    MatExpansionModule,
    MatTableModule,
    MatTabsModule,
    RaUiInputModule,
    RaUiSearchInputModule,
    RaUiSelectModule,
    RaUiImageModule,
    RaUiLoadingSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    RaUiTooltipModule,
    RaUiNavigationTabModule,
    RaUiNavTabItemModule,
    RaUiBannerModule,
    RaUiDividerModule,
    RouterModule.forChild(routes),
  ],
  providers: [AuthorizationService, AuthService, RedirectService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
