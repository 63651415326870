<div class="main-filter-container">
  <div class="container">
    <ra-ui-navigation-tab [selectedIndex]="selectedIndex" (selectedIndexChange)="changedTabIndex($event)" id="edgeNavigationTab">
      <ra-ui-nav-tab-item [label]="navEdgeNodeLabelText"><app-edge-nodes></app-edge-nodes></ra-ui-nav-tab-item>
      <ra-ui-nav-tab-item [label]="navEdgeAppLabelText"><app-edge-apps></app-edge-apps></ra-ui-nav-tab-item>
    </ra-ui-navigation-tab>
  </div>
  <div class="search-filter">
    <div class="search">
      <ra-ui-search-input id="node-search" [hidden]="!isSearchNode" (onAutocompleteItemClick)="searchNodeSelect($event)"
        [disabled]="isDataLoading" #searchInputNode (searchTextChange)="nodeTextChange($event)"
        [listener]="listenerNode" [config]="searchInputConfig"></ra-ui-search-input>
      <ra-ui-search-input id="app-search" [hidden]="isSearchNode" (onAutocompleteItemClick)="searchAppSelect($event)"
        [disabled]="isDataLoading" #searchInputApp (searchTextChange)="appTextChange($event)" [listener]="listenerApp"
        [config]="searchInputConfigApps"></ra-ui-search-input>
    </div>
    <div class="filter">
      <ra-ui-select [label]="selectFilterLabelText" *ngIf="isSearchNode" [(ngModel)]="selectedNodeFilter"
        [disabled]="isDataLoading" (ngModelChange)="sortFilterNodeChange()" [config]="sortConfig"
        [items]="sortOptions"></ra-ui-select>
      <ra-ui-select [label]="selectFilterLabelText" *ngIf="!isSearchNode" [(ngModel)]="selectedAppFilter"
        [disabled]="isDataLoading" (ngModelChange)="sortFilterAppChange()" [config]="sortConfig"
        [items]="sortOptions"></ra-ui-select>
      <br />
    </div>
  </div>
</div>
