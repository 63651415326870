import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoSignInComponent } from './auth/auto-sign-in/auto-sign-in.component';
import {AuthGuardService} from '@rockwell-automation-inc/service';
import { RedirectComponent } from './auth/redirect/redirect.component';
import { CreateOrganizationComponent } from './create-organization/create-organization.component';
import { SubActiveGuard } from './shared/utils/guard/route.guard';
import { CallbackRedirectComponent } from './auth/callback-redirect/callback-redirect.component';
import { ReRouteGuard } from './shared/utils/guard/re-route.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo:'sign-in', //comment for running on node
    pathMatch:'full'
  },
  {
    path:'sign-in',
    component: AutoSignInComponent,
  },
  {
    path:'redirect',
    component: RedirectComponent,
  },
  {
    path: 'callback',
    component:CallbackRedirectComponent
  },
  {
    path: 'provision',
    canActivate:[ReRouteGuard,SubActiveGuard,AuthGuardService],
    component: CreateOrganizationComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
