import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auto-sign-in',
  template: '<div></div>',
})
export class AutoSignInComponent implements OnInit {
  userIsLoggedIn = false;
  targetUrl :string = "/provision"
  constructor(public auth: AuthService) {
    this.targetUrl = this.getRedirectUrl()
  }
  ngOnInit(): void {
    this.login();
  }
  login() {
    const options = {
      redirect_uri: environment.appConfiguration.authConfig.redirectUri,
      audience: environment.appConfiguration.authConfig.audience,
      scope: environment.appConfiguration.authConfig.scope,
      appState: { target: this.targetUrl },
    };
    this.auth.loginWithRedirect(options);
  }

  getRedirectUrl(): string {
    return sessionStorage.getItem('homeUrl') || '/provision'
  }
}
