<div class="projects">
    <ra-ui-static-text class="project-text" [config]="projectTextConfig" [value]="projectValueText"></ra-ui-static-text>
</div>
<div class="give-me-max-width basic-prefix-suffix" >

    <ra-ui-list  [items]="projectList"  [config]="listConfig" (listItemClick)="selectedProject($event)" >
    <ng-template #prefix let-item="item" let-control="control" >
        <ra-ui-static-text class="prefix-content"   [value]="item.name"></ra-ui-static-text>
        <!-- <ra-ui-static-text class="subtext" [disabled]="true" [class]="control.isSelected(item) ? 'white' :'black'" value="{{item.name}}"></ra-ui-static-text> -->
    </ng-template>
    <!-- <ng-template #suffix let-item="item" let-control="control">
        <div class="suffix-content"  *ngIf="control.isSelected(item)">
            <span [raUiBadge]="100"
                  [badgeConfig]="suffixConfig"><span>&nbsp;</span></span>
        </div>
    </ng-template> -->
</ra-ui-list>
</div>
<!-- this element required for automation testing -->
<div class="hiddenNodeLimit">
  <input id="maxNodeLimit" [(ngModel)]="maxNodeLimit" disabled="true" readonly="true">
</div>
