import { Component, OnInit } from '@angular/core';
import { LoadingSpinnerSize, LoadingSpinnerDefinedSize, AnimationType } from '@ra-web-tech-ui-toolkit/indicators/loading-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  size: LoadingSpinnerSize = LoadingSpinnerDefinedSize.XLarge;
  animationType: AnimationType = AnimationType.Spinner;
  constructor() { }

  ngOnInit(): void {
  }

}
