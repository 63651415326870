import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IIconButtonConfig } from '@ra-web-tech-ui-toolkit/buttons/button';
import { IconSize, LinkTarget, TextStyle } from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  IStaticTextConfig,
} from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { Links, aboutDialogText } from 'src/app/constants';
import { IVersion } from '../models/version.model';
import { DashboardService } from '../service/dashboard.service';

@Component({
  selector: 'app-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent implements OnInit {
  links = Links
  aboutDialogTitleText : string = aboutDialogText.aboutDialogTiltleText
  linkModeStaticText : string = aboutDialogText.linkModestaticText
  aboutContent : string = aboutDialogText.aboutContentText
  agreementText : string =  aboutDialogText.agreementText
  licenseAgreementText : string = aboutDialogText.licenseAgreementTextValue
  additionalText : string = aboutDialogText.additionalText
  openSourceInfoText : string = aboutDialogText.openSourceInfoText
  thirdPartyAttributionText : string = aboutDialogText.thirdPartyAttributionsText
  coveredPropertiesText : string = aboutDialogText.propertiesCoveredText
  trademarksListText : string = aboutDialogText.trademarkListDescription
  allRightsReservedText : string = aboutDialogText.allRightReservedText
  raIncDescription : string = aboutDialogText.rockwellAutomationIncText
  textLinkConfig: IStaticTextConfig = {
    linkTarget: LinkTarget.Blank,
  };
  titleTextConfig : IStaticTextConfig = {
    highlightTextStyle : TextStyle.Bold
  }
  buttonConfig: IIconButtonConfig = {
    iconSize: IconSize.M,
    useInvertedMode : true
  };
  serverVersion: string = '';
  UiVersion: string = '';
  yearValue : number = new Date().getFullYear();
  constructor(
    public service: DashboardService,
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  public textConfig: IStaticTextConfig = {
    disableTruncate: true,
  };

  ngOnInit(): void {
    this.getUiVersion();
    this.getServerVersion();
  }

  getUiVersion(): void {
    this.service.fetchUIVersion().subscribe({
      next: (version: any) => {
        this.UiVersion = ` ${JSON.stringify(version)} (UI)`;
      },
    });
  }

  getServerVersion() {
    this.service.fetchServerVersion().subscribe({
      next: (res: IVersion) => {
        this.serverVersion = `${res.productVersion} (server)`;
      },
    });
  }

  closeButtonClicked() {
    this.dialogRef.close();
  }
}
