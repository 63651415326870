import { Injectable } from '@angular/core';
import { debounceTime, interval , Subject, Subscription } from 'rxjs';
import { dashboardApiPoolingInterval } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class PollingService {
  private intervalSubscription!: Subscription;
  private triggerSubject = new Subject<void>();

  constructor() { }

  startPooling(){
    this.triggerSubject.next()
    const interval$ = interval(dashboardApiPoolingInterval || 1000);
    this.intervalSubscription = interval$.subscribe(()=>{
      this.triggerSubject.next();
    })
  }

  stopPolling(){
    if(this.intervalSubscription){
      this.intervalSubscription.unsubscribe();
    }
  }

  getTriggerObservable(){
    return this.triggerSubject.pipe(debounceTime(1000));
  }

}
