<div
  class="project-details"
  [ngClass]="{ 'visibility-hidden': projectName == '' }"
>
  <div class="project-text-div">
    <ra-ui-static-text
      class="project-text"
      [value]="projectName"
    ></ra-ui-static-text>
  </div>
  <ra-ui-divider class="divider" [orientation]="orientation"></ra-ui-divider>
  <div (click)="sendUrl('')">
    <ra-ui-icon-button
      [config]="iconConfig"
      class="project-icons"
      icon="ra-icon-ide-md-dashboard"
      id="customTooltipButton"
      [tooltip]="projectDashboardIconToolTipText"
    >
    </ra-ui-icon-button>
  </div>
  <div (click)="sendUrl('administration/projects/list')">
    <ra-ui-icon-button
      [config]="iconConfig"
      icon="ra-icon-ide-md-show-info"
      id="customTooltipButton"
      [tooltip]="projectInfoIconToolTipText"
    >
    </ra-ui-icon-button>
  </div>
</div>
