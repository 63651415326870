import { Component, OnInit, ViewChild } from '@angular/core';
import {
  SearchInputComponent,
  SearchInputData,
  SearchInputConfig,
  ISearchInputText,
} from '@ra-web-tech-ui-toolkit/form-system/search-input';
import { ISelectConfig } from '@ra-web-tech-ui-toolkit/form-system/select';
import { IMenuItem } from '@ra-web-tech-ui-toolkit/popups/menu';
import { distinctUntilChanged } from 'rxjs';
import { IEdgeAppAPIObj } from '../models/appList.model';
import { INodeListApiObject } from '../models/devicesList.model';
import { IInstanceAPIObj, IInstanceList } from '../models/instances.model';
import { CommunicationService } from '../service/communication.service';
import { SearchUtils } from '../utils/search.utils';
import { searchFilterLabels } from 'src/app/constants';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  providers: [SearchUtils],
})
export class SearchFilterComponent implements OnInit {
  public sortOptions = [
    { value: searchFilterLabels.ascendingValue, label: searchFilterLabels.aToZLabel, id: 'asc-sort' },
    { value: searchFilterLabels.descendingValue, label: searchFilterLabels.zToALabel, id: 'des-sort' },
  ];
  navEdgeNodeLabelText : string = searchFilterLabels.edgeNodeNavLabel
  navEdgeAppLabelText : string = searchFilterLabels.edgeAppNavLabel
  selectFilterLabelText : string = searchFilterLabels.selectFilterLabel

  nodeText: ISearchInputText = { searchInputLabel: searchFilterLabels.edgeNodesSearchText };
  appText: ISearchInputText = { searchInputLabel: searchFilterLabels.edgeAppsSearchText };

  searchDisable: boolean = true;
  searchDisableAppSearch: boolean = true;
  instancesList: Array<IInstanceList> = [];
  isDataLoading: boolean = true;
  selectedIndex: number = 0;

  selectedNodeFilter = this.sortOptions[0].value;
  selectedAppFilter = this.sortOptions[0].value;

  sortConfig: ISelectConfig = {
    disableSidePaddings: true,
  };

  constructor(
    public onChangeService: CommunicationService,
    public utils: SearchUtils
  ) {
    this.getInstancesList();
    this.getChangedNodeList();
    this.getEdgeAppList();
  }

  isSearchNode = true;

  @ViewChild('searchInputNode', { static: false })
  public searchInputNode!: SearchInputComponent;

  @ViewChild('searchInputApp', { static: false })
  public searchInputApp!: SearchInputComponent;

  public afterSearchNodes: Array<any> = [];
  public afterSearchApps: Array<any> = [];
  instances = this.onChangeService.getInstanceChanges$;

  public predicatesNode = [
    {
      id: 'name',
      callback: (value: any): string => {
        //eslint-disable-line @typescript-eslint/no-explicit-any
        return value.name;
      },
      keys: ['n', 'na', 'name'],
    },
  ];

  public predicatesApps = [
    {
      id: 'name',
      callback: (value: any): string => {
        //eslint-disable-line @typescript-eslint/no-explicit-any
        return value.name;
      },
      keys: ['n', 'na', 'name'],
    },
  ];

  public listenerNode = {
    getInput: () => {
      return this.afterSearchNodes;
    },

    sendOutput: (data: SearchInputData) => {
      this.searchInputOutputNode = data;
    },
  };

  public listenerApp = {
    getInput: () => {
      return this.afterSearchApps;
    },

    sendOutput: (data: SearchInputData) => {
      this.searchInputOutputApps = data;
    },
  };

  public searchInputOutputNode: SearchInputData = [];
  public searchInputOutputApps: SearchInputData = [];

  public openAutocompleteByClick = true;

  public searchInputConfig: SearchInputConfig = new SearchInputConfig({
    predicates: this.predicatesNode,
    openAutocompleteByClick: this.openAutocompleteByClick,
    searchInputText: this.nodeText,
  });

  public searchInputConfigApps: SearchInputConfig = new SearchInputConfig({
    predicates: this.predicatesApps,
    openAutocompleteByClick: this.openAutocompleteByClick,
    searchInputText: this.appText,
    maxTypeaheadListSize: 999
  });

  sortFilterNodeChange() {
    this.onChangeService.nodeAfterSearch(this.afterSearchNodes.reverse());
    this.searchInputNode.clearSearch();
  }

  sortFilterAppChange() {
    this.onChangeService.edgeAppAfterSearch(this.afterSearchApps.reverse());
    this.searchInputApp.clearSearch();
  }

  searchNodeSelect(value: IMenuItem) {
    this.onChangeService.nodeAfterSearch(
      this.utils.filterList(this.afterSearchNodes, value.label || '')
    );
  }

  searchAppSelect(value: IMenuItem) {
    this.onChangeService.edgeAppAfterSearch(
      this.utils.filterList(this.afterSearchApps, value.label || '')
    );
  }

  ngOnInit(): void {
    this.checkLoading();
  }

  checkLoading() {
    this.onChangeService.getLoaderChanges$.subscribe((isLoading: boolean) => {
      this.isDataLoading = isLoading;
    });
  }

  getChangedNodeList() {
    this.onChangeService.getNodeChanges$.subscribe(
      async (result: INodeListApiObject) => {
        let nodeArray = result.list;
        await this.utils
          .addInstancesIntoArray(nodeArray, this.instancesList, 'deviceId')
          .then((newNodeArray) => {
            this.afterSearchNodes = newNodeArray;
            this.searchDisable = false;
            this.searchInputNode.refresh();
            this.searchNodeSelect({ label: '' });
            this.selectedNodeFilter = this.sortOptions[0].value;
            this.searchInputNode.clearSearch();
            this.searchInputApp.clearSearch();
          });
      }
    );
  }

  getInstancesList() {
    this.instances.subscribe(
      (response: IInstanceAPIObj) => (this.instancesList = response.list)
    );
  }

  getEdgeAppList() {
    this.onChangeService.getEdgeAppChanges$
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          return JSON.stringify(previous) === JSON.stringify(current);
        })
      )
      .subscribe(async (result: IEdgeAppAPIObj) => {
        let appsArray = result.list;
        await this.utils
          .addInstancesIntoArray(
            appsArray,
            this.instancesList,
            'appId',
            this.afterSearchNodes
          )
          .then((newArray) => {
            this.afterSearchApps = newArray;
            this.searchDisableAppSearch = false;
            this.searchInputApp.refresh();
            this.searchAppSelect({ label: '' });
          });
        this.selectedAppFilter = this.sortOptions[0].value;
      });
  }
  appTextChange(inputString: string) {
    if (inputString === '') {
      this.searchAppSelect({ label: '' });
    }
  }

  nodeTextChange(inputString: string) {
    if (inputString === '') {
      this.searchAppSelect({ label: '' });
    }
  }

  changedTabIndex(index: number): void {
    index == 0 ? (this.isSearchNode = true) : (this.isSearchNode = false);
  }
}
