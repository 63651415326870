<div>
  <div class="dialog-header margin">
    <ra-ui-status-icon
      class="dialog-title-medium"
      id="statusIcon"
      [icon]="activeStatusIcon"
    ></ra-ui-status-icon>
    &nbsp;
    <ra-ui-static-text
      class="dialog-title-medium"
      value="Select Redirection Edge Portal"
    ></ra-ui-static-text>
  </div>
  <div class="margin">
    <ra-ui-static-text
      value="Please select the Edge Manager version"
    ></ra-ui-static-text>
  </div>
  <div class="dialog-footer">
    <ra-ui-main-button
      label="Edge Manager 1.0"
      id="edge1"
      [color]="buttonColor"
      (onClick)="ButtonClicked('zededa')"
    >
    </ra-ui-main-button>
    <ra-ui-outlined-button
      label="Edge Manager 2.0"
      id="edge2"
      [color]="buttonColor"
      (onClick)="ButtonClicked('edge')"
    >
    </ra-ui-outlined-button>
  </div>
</div>
