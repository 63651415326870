<div class="nav_height">
  <ng-container *ngIf="authService.isAuthenticated$ | async">
    <ng-container
      *ngIf="(startSignalRConnection$ | async) && (onHubConnected$ | async)"
    >
    </ng-container>
    <ng-container *ngIf="getUserNotificationCounts$ | async"></ng-container>
    <ng-container *ngIf="signalRUserNotifications$ | async"></ng-container>
    <ng-container *ngIf="notificationAction$ | async"></ng-container>
    <ng-container *ngIf="refreshNotificationsGrid$ | async"></ng-container>
    <ng-container *ngIf="getUserNotifications$ | async"></ng-container>
  </ng-container>
  <lib-common-mat-navbar
    [enableUserActionsSideNav]="false"
    [enableNotificationsSideNav]="true"
    [enableHelpSideNav]="true"
    [enableOrganizationSwitcher]="true"
    [showOrganizationSwitcher]="true"
    [displayChangeOrganization]="true"
    [currentOranizationName]="(getCurrentTenantNameSubject$ | async)!"
    [organizations]="(orgs$ | async)!"
    (changeOrganization)="changeOrg($event)"
    [enableUserProfile]="true"
    [isAuthenticated]="true"
    [accountMenu]="getAccountMenuData$"
    (notificationAction)="notificationAction($event)"
    [unreadNotificationsCount]="(getUnreadNotificationsCount$ | async)!"
    [notifications]="(getNotifications$ | async)!"
    [notificationService$]="notificationService$"
    [logotypeClass]="'edge-manager'"
    [initialTheme]="logoTypeTheme"
    (raLogoClick)="homeClick()"
    [currentTenantId]="(tenantId$ | async)!"
    (logoutClick)="logout()"
    [helpItems]="helpItems"
    (helpItemClick)="helpItemClicked($event)"
    (goToHomeClick)="homeClick()"
    (raLogoClick)="homeClick()"
    [showBanner]="isEdgeApiDown"
    [bannerMessage]="bannerMessage"
    [bannerConfig]="bannerConfig"
    [bannerRaIcon]="bannerRaIcon"
    [mfaReset]="true"
  >
    <div class="main-container">
      <div class="main_content">
        <div class="main_body">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </lib-common-mat-navbar>
</div>

<div
  raUiToast
  class="custom-toast"
  fxLayout="column"
  fxLayoutAlign="center center"
  [toastConfig]="toastConfig"
></div>
