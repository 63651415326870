import { Component, OnInit } from '@angular/core';
import { TextAlign } from '@ra-web-tech-ui-toolkit/cdk/types';
import {
  IStaticTextConfig,
  StaticTextConfig,
} from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { IMenuConfig, IMenuItem } from '@ra-web-tech-ui-toolkit/popups/menu';
import { IOrganisation } from '../models/service.model';
import { CommunicationService } from '../service/communication.service';
import { DividerOrientation } from '@ra-web-tech-ui-toolkit/common-utils/divider';

@Component({
  selector: 'app-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss'],
})
export class ProductHeaderComponent implements OnInit {
  orientation:DividerOrientation = DividerOrientation.vertical
  useInvertedMode = false;
  useAltDisabledColor = false;
  menuState = false;
  TextConfigTitle: IStaticTextConfig = new StaticTextConfig({
    disableTruncate: true,
    allowMultiline: false,
    textAlign: TextAlign.Left,
  });
  menuExplicitConfig: IMenuConfig = {
  };
  selectedOrganisation : string = ""
  items = [
    {
      label:  'Deploy edge app instance',
      icon:   'ra-icon-ide-md-rocket',
      value:  'edge-app-instances/add',
      id:     'deploy-edge-app-instance-link'
    },
    {
      label:  'Add edge node',
      icon:   'ra-icon-ide-md-device',
      value:  'edge-nodes/add',
      id:     'add-edge-node-link'
    },
    {
      label:  'Add project',
      icon:   'ra-icon-ide-md-open-project',
      value:  'administration/projects/add',
      id:     'add-project-link'
    },
    {
      label:  'Request project access',
      icon:   'ra-icon-ide-md-lock',
      value:  'administration/projects/list',
      id:     'request-project-access-link'
    },
    {
      label: 'Import edge app',
      icon: 'ra-icon-ide-md-applications',
      value: 'marketplace/edge-apps/store/list',
      id:     'import-edge-app-link'
    },
  ];
  constructor(private onChangeService:CommunicationService) {}

  ngOnInit(): void {
    this.onChangeService.getTenentChanges$.subscribe((orgDetails:IOrganisation)=>{
      this.selectedOrganisation = orgDetails.name
    })
  }


  ItemClick(item: IMenuItem) {
    switch (item.label) {
      case 'Deploy edge app instance':
        this.onChangeService.redirectTo(item.value);
        break;
      case 'Add edge node':
        this.onChangeService.checkNodeLimit();
        break;
      case 'Add project':
        this.onChangeService.redirectTo(item.value);
        break;
      case 'Request project access':
        this.onChangeService.redirectTo(item.value);
        break;
      case 'Import edge app':
        this.onChangeService.redirectTo(item.value);
        break;
      default:
        break;
    }
  }

}
