import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IUserDetails } from '../model/userDetails.model';
import { RedirectService } from '../service/redirect.service';
import { MatDialog } from '@angular/material/dialog';
import { RedirectDialogComponent } from './Dialog/redirect-dialog.component';
import { AuthorizationService } from 'src/app/shared/utils/services/authorization.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
  providers: [RedirectService, AuthorizationService],
})
export class RedirectComponent implements OnInit {
  userDetails: IUserDetails | undefined;
  constructor(
    public window: Window,
    private apiService: RedirectService,
    public dialog: MatDialog,
    private tokenService: AuthorizationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setHomePage('/dashboard');
    this.router.navigate(['dashboard'])
    // this.getOrgAndEmail();
  }

  popUpOption() {
    const dialogRef = this.dialog.open(RedirectDialogComponent, {
      minWidth: 400,
      data: { redirectTo: '' },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'zededa') {
        this.loginZededaRedirect(this.userDetails);
      } else if (result === 'edge') {
        this.loginEdgeManager();
      }
    });
  }

  getOrgAndEmail() {
    this.apiService.getOrgNameEmail().subscribe({
      next: (result: IUserDetails) => {
        this.userDetails = result;
        this.checkRedirectEnv(result);
      },
      error: () => {
        this.checkRedirectEnv();
      },
    });
  }

  checkRedirectEnv(userDetails?: IUserDetails) {
    if (!environment.appConfiguration.isRedirectOption) {
      this.loginZededaRedirect(userDetails);
      return;
    }
    if (environment.appConfiguration.edgeManagerUrl) {
      this.popUpOption();
    } else {
      this.loginZededaRedirect(userDetails);
    }
  }

  loginZededaRedirect(UserDetails?: IUserDetails): void {
    if (UserDetails) {
      this.window.location.href =
        environment.appConfiguration.redirectUrl +
        `?user=${UserDetails.userEmail}&enterprise=${UserDetails.enterpriseName}`;
    } else {
      this.window.location.href =
        environment.appConfiguration.redirectUrl || '';
    }
  }

  loginEdgeManager() {
    this.window.location.href = `${
      environment.appConfiguration.edgeManagerUrl
    }?tenentId=${this.tokenService.readTenantId() ?? ''}`;
  }

  setHomePage(url:string){
    sessionStorage.setItem('homeUrl',url)
  }
}
