import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, lastValueFrom, map, Observable, of } from 'rxjs';
import { APP_CONFIG } from 'src/app/constants';
import { Configuration } from 'src/app/models/config.model';
import { environment } from 'src/environments/environment';
@Injectable()
export class AppConfigService {
  gainsightTag!: string;
  constructor(
    @Inject(DOCUMENT) private readonly doc: Document,
    @Inject(APP_CONFIG) private readonly config: Configuration
  ) {
    this.gainsightTag = environment.appConfiguration.gainsightTag;
  }
  async init(): Promise<any> {
    await lastValueFrom(this.injectGainsight())
      .then(() => {})
      .catch((err) => {
        throw err;
      });
  }

  injectGainsight(): Observable<void> {
    if (this.config.gainsightTag) {
      return this.injectGainsightHelper();
    } else {
      return EMPTY;
    }
  }
  //script to inject for gainsight

  private generateGainsightScript(): Observable<string> {
    return of(`(function (n,t,a,e,co){var i="aptrinsic";n[i]=n[i]||function(){
      (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
    var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
    var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
  })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js","${this.gainsightTag}");`);
  }

  private injectGainsightHelper(): Observable<void> {
    return this.generateGainsightScript().pipe(
      map((gainsightScript) => {
        const script = this.doc.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = gainsightScript;
        const head = this.doc.getElementsByTagName('head')[0];
        head.appendChild(script);
      })
    );
  }
}
