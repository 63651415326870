import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import { AuthorizationService } from "../services/authorization.service";
import { SharedMessageService } from "../services/shared-message.service";
import { environment } from "src/environments/environment";

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {

  constructor(private tokenService:AuthorizationService,private messageService:SharedMessageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes(environment.appConfiguration.authConfig.domain)) {
      req = req.clone({
        setHeaders: { 'tenantId': this.tokenService.readTenantId(), }
      });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if(error.url?.includes(environment.appConfiguration.apiBaseUrl)){
            this.messageService.getErrors(error)
          }
        } else {
          return throwError(() => error);
        }
        return throwError(() => error);
      })
    )
  }
}
