import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationType } from '@ra-web-tech-ui-toolkit/cdk/types';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from '../authorization.service';
import { CommunicationService } from 'src/app/dashboard/service/communication.service';
import { entitlementText } from 'src/app/constants';

@Component({
  selector: 'app-entitlement-dialog',
  templateUrl: './entitlement.component.html',
  styleUrls: ['./entitlement.component.scss'],
})
export class EntitlementComponent {
  entitlementNotPurchasedText : string = entitlementText.entitlementNotPurchasedTextValue
  redirectToFTHubText : string = entitlementText.redirectToFTHubText
  goToFactoryTalkHubButtonLabel : string = entitlementText.goToFactoryTalkHubButtonLabelText
  cancelButtonLabel : string = entitlementText.cancelButtonLabelText
  constructor(
    private sharedService: AuthorizationService,
    public window: Window,
    private onChangeService: CommunicationService,
    private dialog: MatDialog
  ) {}
  activeStatusIcon = NotificationType.Info;

  onRedirect() {
    this.window.open(
      environment.appConfiguration.factoryTalkHubBaseUrl + '/entitlement',
      '_blank'
    );
  }

  onCancel() {
    this.dialog.closeAll();
    const oldTenantId = this.sharedService.readOldTenantId();
    this.onChangeService.changedOrgTenant(oldTenantId);
  }
}
