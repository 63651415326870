import { Component, OnInit } from '@angular/core';
import {
  BadgePosition,
  BadgeSize,
  BADGE_STATUS_COLORS,
} from '@ra-web-tech-ui-toolkit/indicators/badge';
import { TextStyle } from '@ra-web-tech-ui-toolkit/cdk/types';
import { IStaticTextConfig } from '@ra-web-tech-ui-toolkit/common-utils/static-text';
import { IListConfig } from '@ra-web-tech-ui-toolkit/lists/list';
import { distinctUntilChanged } from 'rxjs';
import { Projects } from '../models/dashboard.model';
import { IProjectSelected } from '../models/project.model';
import { IOrganisation } from '../models/service.model';
import { CommunicationService } from '../service/communication.service';
import { DashboardService } from '../service/dashboard.service';
import {
  DialogService,
  IDialogButton,
  IDialogConfig,
} from '@ra-web-tech-ui-toolkit/popups/dialog';
import {
  ActionButtonStyles,
  NotificationType,
} from '@ra-web-tech-ui-toolkit/cdk/types';
import { IAddEdgeNodeAPIObj } from '../models/addEdgeNode.model';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from 'src/app/shared/utils/services/authorization.service';
import { MatDialog } from '@angular/material/dialog';
import { LoaderComponent } from '../utils/loader/loader.component';
import { projectListValue } from 'src/app/constants';
@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent implements OnInit {
  projectTextConfig: IStaticTextConfig = {
    highlightTextStyle: TextStyle.Bold,
  };
  maxNodeLimit : number = 0;
  suffixConfig = {
    position: BadgePosition.Before,
    color: BADGE_STATUS_COLORS.Error,
    size: BadgeSize.Small,
    cap: 99,
  };

  projectValueText : string = projectListValue.projectListValueText

  listConfig: IListConfig = {
    prefixWidth: 160,
    suffixWidth: 50,
    tabIndex: -1,
  };

  dialogRef!: any;
  config!: IDialogConfig;
  public lastClickedButton: any;

  projectList!: Projects[];
  isNodeAllowed: boolean = true;

  constructor(
    private dashboardService: DashboardService,
    private onChangeService: CommunicationService,
    public window: Window,
    private authService: AuthorizationService,
    public dialogService: DialogService,
    private dialog: MatDialog,
    ) {

    this.onChangeService.getTenentChanges$
      .pipe(
        distinctUntilChanged(
          (previous: IOrganisation, current: IOrganisation) => {
            return JSON.stringify(previous) === JSON.stringify(current);
          }
        )
      )
      .subscribe((tenantData: IOrganisation) => {
        this.projectList = [];
        this.getProjectList();
        this.dialog.open(LoaderComponent, {
          disableClose:true,
          panelClass: 'borderless-dialog'
        });
      });
  }

  ngOnInit(): void {
    this.onChangeService.getNodeLimitAllowed$.subscribe(()=>{
          this.isNodeAllowed ? this.onChangeService.redirectTo("edge-nodes/add") : this.openAddNodeMessageDialog()
    })
  }

  getProjectList() {
    this.dashboardService.getProjectList().subscribe({
      next: (response: any) => {
        this.dialog.closeAll();
        this.projectList = response;
        if (this.projectList.length > 0) {
          this.projectList[0].isDefault = true;
          this.selectedProject({
            item: {
              name: this.projectList[0].name || '',
              id: this.projectList[0].id || '',
            },
          });
        }
        this.addNodeLimitReachedDialog();
      },
    });
  }

  selectedProject(event: any) {
    const projectObj: IProjectSelected = {
      name: event.item.name || '',
      id: event.item.id || '',
    };
    this.onChangeService.projectChange(projectObj);
  }

  addNodeLimitReachedDialog() {
    this.dashboardService.checkNodeLimitAndUsage().subscribe({
      next: (response: IAddEdgeNodeAPIObj) => {
        const nodeLimit = response.enterpriseEntitlements[0].devices[0].limit;
        this.maxNodeLimit = nodeLimit
        const nodeUsage = response.enterpriseEntitlements[0].devices[0].usage;
        if (nodeLimit >= 0 && nodeLimit === nodeUsage) {
          this.openAddNodeMessageDialog();
          this.isNodeAllowed = false
        }
      },
      error: (error) => {
          this.maxNodeLimit = 0;
          this.isNodeAllowed = false
          return error;
      },
    });
  }

  openAddNodeMessageDialog() {
    if (this.dialogRef) return;
    this.config = {
      title: projectListValue.cantAddEdgeNodeText,
      message: projectListValue.nodeEntitlementsReachedText,
      buttons: [
        {
          label: projectListValue.purchaseNodeEntitlementsButtonLabel,
          buttonStyle: ActionButtonStyles.Main,
          shouldAutofocus: true,
        },
        { label: projectListValue.closeButtonLabel, buttonStyle: ActionButtonStyles.Outlined },
      ],
      messageType: NotificationType.Warning,
      showCloseIconButton: true,
      hideTitleIcon: false,
      closeByClickOutside: false,
    };
    let dialog = this.dialogService.openDialog(this.config);
    dialog.componentInstance.onClick.subscribe((data: IDialogButton) => {
      if (
        (this.lastClickedButton = data.label.startsWith(
          projectListValue.purchaseNodeEntitlementsButtonLabel
        ))
      ) {
        this.window.open(
          environment.appConfiguration.factoryTalkHubBaseUrl + '/entitlement',
          '_blank'
        );
        this.dialogRef = undefined;
      }
    });
  }
}
