<app-product-header ></app-product-header>
<div class="main-details">
  <section class="main-detail-content-example-slot">
    <ra-ui-main-detail-content
      [title]="mainDetailContent.title"
      [subtitle]="mainDetailContent.subtitle"
      [disableSidePaddings]="mainDetailContent.disableSidePaddings"
      [disableTopDivider]="mainDetailContent.disableTopDivider"
      [disableBottomDivider]="mainDetailContent.disableBottomDivider"
    >
      <ng-container mainDetailLeftPane>
        <app-project-list></app-project-list>
      </ng-container>
      <ng-container mainDetailDetailPane>
        <app-details-heading ></app-details-heading>
        <app-card-details [nodeDetails]="nodeSummary" ></app-card-details>
        <app-search-filter></app-search-filter>
      </ng-container>
    </ra-ui-main-detail-content>
  </section>
</div>
