import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
} from '@angular/router';
import { AuthorizationService } from '../services/authorization.service';

@Injectable()
export class SubActiveGuard implements CanActivate {
  constructor(private sharedService: AuthorizationService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const tenantId = route.queryParamMap.get('tenantId')
    const serviceId = route.queryParamMap.get('serviceId')
    if(tenantId){
      this.sharedService.setTenantId(tenantId)
    }
    if(serviceId){
      this.sharedService.setServiceId(serviceId)
    }
    return true
 }
}
