import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IAppSearchList } from '../models/appList.model';
import { INodeListApiObject, INodeSearchList } from '../models/devicesList.model';
import { IProjectSelected } from '../models/project.model';
import { IOrganisation } from '../models/service.model';
@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  //For loader
  private loader = new Subject<boolean>();
  getLoaderChanges$ = this.loader.asObservable();
  public oldOrgTenantSubject = new Subject<string>();

  // For API's
  private project = new Subject<IProjectSelected>();
  private urlRedirection = new Subject<string>();
  private nodeDetails = new Subject<any>();
  private instanceDetails = new Subject<any>();
  private edgeAppDetails = new Subject<any>();
  private edgeAppSearchDetails = new Subject<any>();
  private nodeSearchDetails = new Subject<any>();
  private currentTenent = new Subject<IOrganisation>();
  private nodeLimitAllowed = new Subject<string>();
  getProjectChanges$ = this.project.asObservable();
  redirectionRequests$ = this.urlRedirection.asObservable();
  getNodeChanges$ = this.nodeDetails.asObservable();
  getInstanceChanges$ = this.instanceDetails.asObservable();
  getEdgeAppChanges$ = this.edgeAppDetails.asObservable();
  getEdgeAppSearchChanges$ = this.edgeAppSearchDetails.asObservable();
  getNodeSearchChanges$ = this.nodeSearchDetails.asObservable();
  getTenentChanges$ = this.currentTenent.asObservable();
  getNodeLimitAllowed$ = this.nodeLimitAllowed.asObservable();
  getOldTenantIdSubject$ = this.oldOrgTenantSubject.asObservable();

  projectChange(projectObj:IProjectSelected){
    this.project.next(projectObj)
  };

  tenentChange(tenentId:string,name:string){
    this.currentTenent.next({tenentId,name});
  }

  redirectTo(url:string){
    this.urlRedirection.next(url)
  }

  nodeChangedByProject(Nodes : INodeListApiObject){
    this.nodeDetails.next(Nodes)
  }

  instanceChangedByProject(Instance : any){
    this.instanceDetails.next(Instance)
  }

  nodeAfterSearch(newNodeArray : Array<INodeSearchList>){
    this.nodeSearchDetails.next(newNodeArray)
  }

  edgeAppFetch(newEdgeApp : any){
    this.edgeAppDetails.next(newEdgeApp)
  }

  edgeAppAfterSearch(newEdgeAppArray : Array<IAppSearchList>){
    this.edgeAppSearchDetails.next(newEdgeAppArray)
  }

  loaderChanged(isLoading : boolean){
    this.loader.next(isLoading)
  }

  checkNodeLimit(){
    this.nodeLimitAllowed.next('')
  }

  changedOrgTenant(oldTenantId:string){
    this.oldOrgTenantSubject.next(oldTenantId)
  }

}
