import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor() {}

  setTenantId(token: string) {
    sessionStorage.setItem('tenantId', token);
  }

  setServiceId(Id: string) {
    sessionStorage.setItem('serviceId', Id);
  }

  setCurrentOrgName(orgName: string) {
    sessionStorage.setItem('currentOrg', orgName);
  }

  setOldTenantId(token: string) {
    sessionStorage.setItem('oldTenantId', token);
  }

  setOldOrgName(orgName: string) {
    sessionStorage.setItem('oldOrg', orgName);
  }

  removeSession() {
    sessionStorage.clear();
  }

  readOldTenantId() {
    return sessionStorage.getItem('oldTenantId') || '';
  }

  readOldOrgName() {
    return sessionStorage.getItem('oldOrg') || '';
  }
  readTenantId() {
    return sessionStorage.getItem('tenantId') || '';
  }

  readCurrentOrgName() {
    return sessionStorage.getItem('currentOrg') || '';
  }

  readServiceId() {
    return sessionStorage.getItem('serviceId') || '';
  }

  setNodeLimit(isAllowedNode:boolean){
    return sessionStorage.setItem('edgeLimit', JSON.stringify(isAllowedNode))
  }

  readNodeLimit(){
    return sessionStorage.getItem('edgeLimit') || false
  }

}
