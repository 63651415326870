import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IEdgeAppAPIObj } from '../models/appList.model';
import { INodeListApiObject } from '../models/devicesList.model';
import { IInstanceAPIObj } from '../models/instances.model';
import { IVersion } from '../models/version.model';
import { IAddEdgeNodeAPIObj } from '../models/addEdgeNode.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  projectList = new BehaviorSubject({});
  constructor(private http: HttpClient) { }

  getProjectList() {
    return this.http.get(environment.appConfiguration.apiBaseUrl + "/v1/Dashboard/resource-group-list")
  }

  getDevicesList(projectName:string) {
    return this.http.get<INodeListApiObject>(environment.appConfiguration.apiBaseUrl + "/v1/Dashboard/edge-node-status-config?projectName=" + projectName)
  }

  getInstanceByProjectName(projectName:string){
    return this.http.get<IInstanceAPIObj>(environment.appConfiguration.apiBaseUrl + "/v1/Dashboard/edge-app-instances?projectName=" + projectName)
  }

  getEdgeAppList(){
    return this.http.get<IEdgeAppAPIObj>(environment.appConfiguration.apiBaseUrl + "/v1/Dashboard/edge-app-definition")
  }

  checkNodeLimitAndUsage(){
    return this.http.get<IAddEdgeNodeAPIObj>(environment.appConfiguration.apiBaseUrl + "/v1/Dashboard/edge-node-allowedenterprises")
  }

  fetchServerVersion() {
    return this.http.get<IVersion>(environment.appConfiguration.apiBaseUrl);
  }

  fetchUIVersion() {
    return this.http.get<string>('/version');
  }

}
