import { InjectionToken } from "@angular/core";
import { environment } from "src/environments/environment";
import { Configuration } from "./models/config.model";

export const APP_CONFIG: InjectionToken<Configuration>
  = new InjectionToken<Configuration>('Application Configuration');
export const PAGE_SIZE = 25;
export const GRID_HEADER_HEIGHT = 48;
export const GRID_ROW_HEIGHT = 48;
const apiVersion = '/api/v1'
export const createOrgApi =  `${apiVersion}/Tenant`;
export const cancelOrgApi =  `${apiVersion}/Tenant/cancel`;
export const defaultOrg = `${apiVersion}/Tenant/tenant-name`;
export const checkOrgState = `${apiVersion}/Tenant/service-provisioning-status`;

export const defaultPage = "/provision";
export const apiPoolingInterval = 5000
export const dashboardApiPoolingInterval = environment.appConfiguration.dashboardApiPoolingInterval || 30000

export const ServerErrors = {
  internalServerError: { title: 'Internal Server Error!', message: 'Internal Server Error!' },
  notFountServerError: {
    title: '404 not found!!',
    message: '404 not found!!',
  },
};

export const errorPagesTitle = {
  notAuthorized : "Access denied due to invalid credentials",
  notFound : "This page does not seem to exist.",
  internalServerError : "Please refresh this page or try again later"
}

export const Links = {
  releaseNotes : "https://helpdesk.cloud.rockwellautomation.com/FactoryTalk_Hub/rel_notes.html#ftcs-rel-notes",
  licenseAgreement : "https://helpdesk.cloud.rockwellautomation.com/FactoryTalk_Hub/rel_notes.html#ftcs-rel-notes",
  trademarksList : "https://www.rockwellautomation.com/en-us/company/about-us/legal-notices/trademarks.html",
}

export const errorPagesText = {
  internalServerErrorText : "Error 500",
  pageNotFoundErrorText : "Error 404",
  unauthorizedErrorText : "Error 401"
}

export const searchFilterLabels = {
  edgeNodeNavLabel : "Edge Nodes",
  edgeAppNavLabel : "Edge Apps",
  selectFilterLabel : "Sort : A to Z",
  edgeNodesSearchText : "Edge Nodes Search",
  edgeAppsSearchText : "Edge Apps Search",
  ascendingValue : "Ascending",
  aToZLabel : "A to Z",
  descendingValue : "Descending",
  zToALabel : "Z to A"
}

export const projectListValue = {
  projectListValueText : "Projects",
  cantAddEdgeNodeText : "Can't add a new edge node",
  nodeEntitlementsReachedText : "This organization has consumed all of its node entitlements.Please purchase more to add more nodes.",
  purchaseNodeEntitlementsButtonLabel : "Purchase node entitlements",
  closeButtonLabel : "Close"
}

export const edgeNodeLabels = {
  buttonTextLabelShowLess : "Show less",
  buttonTextLabelShowAll : "Show all",
  oulinedButtonTextLabel : "Add edge node",
  contentTextValue : "Configure and edge node to host your edge app instances."
}

export const edgeAppLabels = {
  vulnerabilitiesNotAvailable : "N/A Vulnerabilities",
  vulnerabilitiesToolTip: "Vulnerabilities Not Available",
  buttonTextLabelShowLess : "Show less",
  buttonTextLabelShowAll : "Show all",
  deployOulinedButtonTextLabel : "Deploy edge app instance",
  importOulinedButtonTextLabel : "Import edge apps",
  versionNotAvailableValue : "N/A Version",
  contentTextValue : "Deploy app instances to your edge nodes to see what apps you have install here."
}

export const detailsHeadingText = {
  projectDashboardIconToolTip : "Project Dashboard",
  projectInfoIconToolTip : "Project details"
}

export const dashboardLayoutText = {
  bannerMessageText : "Connection to Edge Manager has been lost. We are working to restore it.",
  helpItemDisplayText : "About FactoryTalk Edge Manager"
}

export const cardDetailsText = {
  staticTextForOnline : "Online",
  addEdgeNodeValueText : "Add Edge nodes",
  vulnerabilitiesValueText : "Vulnerabilities",
  updateValueText : "Updates",
  addAppInstancesText : "Add app instances",
}

export const aboutDialogText = {
  aboutDialogTiltleText : "FactoryTalk® Edge™ Manager",
  linkModestaticText : "See Release Notes",
  aboutContentText : `This software is protected by copyright law and international treaties. Unauthorized access, use or distribution of this program,
  or any portion of it, may result in severe civil an criminal penalties, and will be prosecuted to the maximum extent possible under the law. `,
  agreementText : "See the ",
  licenseAgreementTextValue : "End User License Agreement",
  additionalText : "for additional terms of use.",
  openSourceInfoText : "For information about open source software used in this product, see",
  thirdPartyAttributionsText : "third-party attributions.",
  propertiesCoveredText : "All properties mentioned are covered as Rockwell Automation ",
  trademarkListDescription : " trademarks list.",
  allRightReservedText : "All Rights Reserved.",
  rockwellAutomationIncText : " Rockwell Automation Technologies, Inc."
}

export const entitlementText = {
  entitlementNotPurchasedTextValue : "Entitlement not purchased",
  redirectToFTHubText : "Your going to be redirect to FTHub",
  goToFactoryTalkHubButtonLabelText : "Go to Factory Talk Hub",
  cancelButtonLabelText : "Cancel"
}
